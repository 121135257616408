import { useFetch } from "./fetch";
import { setCarroussel } from "./carroussel";
import { string_to_slug, stripHtml, cloudinarySetup, addItalic } from "./utils";

async function renderHome() {
  document.title = "Accueil - ANRAT";
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Association Nationale de Recherche et d'Action Théâtrale<"
    );
  const $main = document.getElementById("main");
  $main.innerHTML = "";
  $main.classList.add("grid");

  const $header = document.querySelector("header");
  $header.style.backgroundImage = `url("https://res.cloudinary.com/anrat/image/upload/f_auto/large_reseau_9516e90a5c.jpg")`;
  let screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
  if (screenWidth < 600) {
    $header.style.backgroundImage = "none";
  }

  let news = {
    data: await useFetch("accueil"),
    array: [],
    html: "",
    dots: "",
  };

  news.data.slider.actualite1 && news.array.push(news.data.slider.actualite1);
  news.data.slider.actualite2 && news.array.push(news.data.slider.actualite2);
  news.data.slider.actualite3 && news.array.push(news.data.slider.actualite3);
  news.data.slider.actualite4 && news.array.push(news.data.slider.actualite4);
  news.data.slider.actualite5 && news.array.push(news.data.slider.actualite5);
  news.data.slider.actualite6 && news.array.push(news.data.slider.actualite6);

  news.array.map((newe) => {
    news.html += `<div class="car__mySlides car__fade">
            <div class="car__content">
              <img
                src="${
                  (newe.image?.formats?.medium?.url &&
                    cloudinarySetup(newe.image?.formats?.medium?.url, true)) ||
                  (newe.image?.formats?.small?.url &&
                    cloudinarySetup(newe.image?.formats?.small?.url, true)) ||
                  (newe.image?.formats?.thumbnail?.url &&
                    cloudinarySetup(
                      newe.image?.formats?.thumbnail?.url,
                      true
                    )) ||
                  (newe.imageUrl && cloudinarySetup(newe.imageUrl, true)) ||
                  (newe.image?.url && cloudinarySetup(newe.image?.url)) ||
                  "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
                }"
              />
              <div class="car__text">
                <a href="/actualites/${string_to_slug(newe.title)}">
                  <h2>${addItalic(newe.title?.substring(0, 76))}${
      newe.title?.length > 76 ? "..." : ""
    }</h2>
                </a>
                <p class="bold">${addItalic(newe.subtitle)}</p>
                <p>
                  ${stripHtml(newe.content).substring(0, 140)} ...
                </p>
                <a href="/actualites/${string_to_slug(
                  newe.title
                )}" class="out-btn-black">EN SAVOIR PLUS</a>
              </div>
            </div>
          </div>`;
    news.dots += `<span class="car__dot"></span>`;
  });

  /* home blocs */
  let blocs = {
    data: await useFetch("accueil"),
    html: "",
  };

  blocs.data.hbloc.map((bloc, id) => {
    blocs.html += `<a href="${bloc.link}" class="hbloc-links">
        <div class="hbloc-${id + 1} hbloc">
          <img src="${bloc.image?.formats?.small?.url}" alt="${
      bloc.title
    }" class="hbloc__image" />
          <img src="${
            bloc.icon?.formats?.small?.url || bloc.icon?.url
          }" alt="Icone" class="hbloc__icon" />
          <h3>${bloc.title}</h3>
          <p>
            ${bloc.content}
          </p>
        </div>
      </a>`;
  });

  $main.innerHTML = `<div class="news">
        <h1>ACTUALITÉS</h1>
        <div class="car__container">
          ${news.html}
        </div>
        <div class="car__dots">
          <a href="#" id="previous">
            <span class="material-icons-round"> arrow_back_ios_new </span>
          </a>
          ${news.dots}
          <a href="#" id="next">
            <span class="material-icons-round"> arrow_forward_ios </span>
          </a>
        </div>
      </div>
      ${blocs.html}`;

  setTimeout(setCarroussel(), 1500);
}

export { renderHome };
