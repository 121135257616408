import { useFetch } from "./fetch";
import { subscribe } from "./newsletter";

async function renderFooter() {
  let json = await useFetch("accueil");

  /* social networks */
  const $social = {
    data: json.socialNetworks,
    dom: document.getElementById("social"),
  };
  $social.dom.innerHTML = "";
  $social.data.map((sn) => {
    $social.dom.innerHTML += `<a href="${sn.url}" target="_blank">
            <img src="${sn.image.url}" alt="${sn.name}" />
          </a>`;
  });

  /* newsletter */
  const $newsletterFooter = document.getElementById("newsletterFooter");
  const $newsletterInputFooter = document.getElementById(
    "newsletterInputFooter"
  );

  $newsletterFooter.addEventListener("submit", (ev) => {
    ev.preventDefault();
    subscribe($newsletterInputFooter.value);
  });

  /* contact */
  const $contact = {
    data: json.contact,
    dom: document.getElementById("contact"),
  };
  $contact.dom.innerHTML = `<h3>Nous contacter</h3>
        <p>${$contact.data.adress1 ? $contact.data.adress1 : ""}</p>
        <p>${$contact.data.adress2 ? $contact.data.adress2 : ""}</p>
        <p>${$contact.data.adress3 ? $contact.data.adress3 : ""}</p>
        <p>${$contact.data.adress4 ? $contact.data.adress4 : ""}</p>
        <p>${$contact.data.phone ? $contact.data.phone : ""}</p>
        <p>
          <a href="mailto:${$contact.data.email ? $contact.data.email : ""}">${
    $contact.data.email ? $contact.data.email : ""
  }</a>
        </p>
        <p>${$contact.data.text ? $contact.data.text : ""}</p>
        <p>${$contact.data.SIRET ? $contact.data.SIRET : ""}</p>`;

  /* friends */
  const $friends = {
    data: json.friends,
    dom: document.getElementById("friends"),
  };
  $friends.dom.innerHTML = "";
  $friends.data.map((friend) => {
    $friends.dom.innerHTML += `<li><a href="${friend.website}" target="_blank">${friend.name}</a></li>`;
  });

  /* banner */
  const $banner = {
    data: json.wall,
    quote: document.getElementById("quote"),
  };
  $banner.quote.innerHTML = `« ${$banner.data.quote} » <span class="author">${$banner.data.author}</span>`;
}

export { renderFooter };
