import { useFetch } from "./fetch";
import {
  compareDate,
  cloudinarySetup,
  stripHtml,
  string_to_slug,
  mutation,
  addItalic,
} from "./utils";
import { renderBlocs } from "./blocs";

let firstMutation = true;

async function renderFiltredList(tag) {
  let items = [];
  switch (tag) {
    case "tag-publications":
      items = await useFetch("ressource-publications?anrat=Autre");
      for (let i in items) {
        items[i].html = "";
        items[i].cta = {
          text: "En savoir plus",
          href: "/ressources/publications/" + string_to_slug(items[i].title),
        };
        items[i].type = "publications";
      }
      break;
    case "tag-outils":
      items = await useFetch("ressource-outils?anrat=Autre");
      for (let i in items) {
        items[i].html = ``;
        items[i].cta = {
          text: "Lire le PDF",
          href: items[i].pdf?.url || items[i].pdfUrl,
        };
        items[i].type = "outils";
      }
      break;
    case "tag-reference":
      items = await useFetch("ressource-textes");
      for (let i in items) {
        items[i].html = "";
        items[i].cta = {
          text: "Lire le PDF",
          href: items[i].pdf?.url || items[i].pdfUrl,
        };
        items[i].type = "reference";
      }
      break;
    case "tag-en-ligne":
      items = await useFetch("ressource-en-lignes");
      for (let i in items) {
        items[i].html = "";
        items[i].cta = {
          text: "Visiter le site web",
          href: items[i].website,
        };
        items[i].type = "en-ligne";
      }
      break;
    case "":
      let outils = await useFetch("ressource-outils?anrat=Autre");
      for (let i in outils) {
        outils[i].html = ``;
        outils[i].cta = {
          text: "Lire le PDF",
          href: outils[i].pdf?.url || outils[i].pdfUrl,
        };
        outils[i].type = "outils";
      }
      let publications = await useFetch("ressource-publications?anrat=Autre");
      for (let i in publications) {
        publications[i].html = "";
        publications[i].cta = {
          text: "En savoir plus",
          href:
            "/ressources/publications/" + string_to_slug(publications[i].title),
        };
        publications[i].type = "publications";
      }
      let reference = await useFetch("ressource-textes");
      for (let i in reference) {
        reference[i].html = "";
        reference[i].cta = {
          text: "Lire le PDF",
          href: reference[i].pdf?.url || reference[i].pdfUrl,
        };
        reference[i].type = "reference";
      }
      let enLigne = await useFetch("ressource-en-lignes");
      for (let i in enLigne) {
        enLigne[i].html = "";
        enLigne[i].cta = {
          text: "Visiter le site web",
          href: enLigne[i].website,
        };
        enLigne[i].type = "en-ligne";
      }

      items = [...outils, ...publications, ...reference, ...enLigne];
      break;
  }
  items.sort(compareDate).reverse();

  let listHTML = "";
  items.map((item, id) => {
    listHTML += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            ${item.category ? `<p><strong>${item.category}</strong></p>` : ""}
            <p>${
              item.type === "outils" || item.type === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
      item.type !== "outils" &&
      item.type !== "en-ligne" &&
      stripHtml(item.content).length > 280
        ? "..."
        : ""
    }</p>
            <a href="${
              item.cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : item.cta.href
            }" class="out-btn-black" ${
      item.type === "publications" ? "" : 'target="_blank"'
    }>${item.cta.text}</a>
          </div>
        </div>
        ${id != items.length - 1 ? "<hr>" : ""}`;
  });

  // Render HTML
  const $list = document.getElementById("list-items");
  $list.innerHTML = listHTML;
}

function handleTags() {
  const $tagPublication = document.getElementById("tag-publications");
  const $tagOutils = document.getElementById("tag-outils");
  const $tagReference = document.getElementById("tag-reference");
  const $tagEnLigne = document.getElementById("tag-en-ligne");

  const $tags = [$tagPublication, $tagOutils, $tagReference, $tagEnLigne];

  if (firstMutation) {
    renderFiltredList("");
    $tags.forEach(($tag) => {
      $tag.addEventListener("click", (ev) => {
        ev.preventDefault();
        if ($tag.classList.contains("active")) {
          $tag.classList.remove("active");
          renderFiltredList("");
        } else {
          $tags.forEach((tag) => {
            tag.classList.remove("active");
          });
          $tag.classList.add("active");
          renderFiltredList($tag.id);
        }
      });
      firstMutation = false;
    });
  }
}

async function renderOtherRessources() {
  // Render Skeloton
  const $main = document.getElementById("main");
  $main.classList.remove("grid");
  $main.innerHTML = "";
  let ariane = ["Ressources", "Les autres ressources"];
  let blocsHTML = await renderBlocs();

  mutation($main, handleTags);

  $main.innerHTML = `<div class="list">
        <div class="ariane">
         <a href="/index.html">Accueil</a> / <a href="/${
           window.location.href.split("/")[3]
         }">${ariane[0]}</a> / ${ariane[1] ? ariane[1] : ""}
        </div>
        <div class="title" style="margin-bottom: 1rem;">
          <img src="https://res.cloudinary.com/anrat/image/upload/f_auto/formations_846a20910d.png" alt="Formations">
          <h1>${ariane[1] || ariane[0]}</h1>
        </div>
        <p>Filtrez les ressources par catégories :</p>
        <div class="filters">
        <div class="filters__bloc">
          <a href="#" class="out-btn-black" id="tag-publications">Publications</a>
          <a href="#" class="out-btn-black" id="tag-outils">Outils</a>
        </div>
        <div class="filters__bloc">
          <a href="#" class="out-btn-black" id="tag-reference">Textes de référence</a>
          <a href="#" class="out-btn-black" id="tag-en-ligne">Sites web</a>
        </div>
        </div>
        <span id="list-items">
        </span>
      </div>
  <div class="blocs">
        ${blocsHTML}
      </div>`;
}

export { renderOtherRessources };
