async function useFetch(path) {
  const response = await fetch(`https://anrat-back.herokuapp.com/${path}`);

  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    console.log("UseFetch error : ", message);
    // throw new Error(message);
  }

  const json = await response.json();
  return json;
}

async function usePost(path, data) {
  let request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(
      "https://anrat-back.herokuapp.com/" + path,
      request
    );
    return response;
  } catch (error) {
    return error;
  }
}

export { useFetch, usePost };
