function setCarroussel() {
  function nextSlide(clicked = false) {
    var i;
    var slides = document.getElementsByClassName("car__mySlides");
    var dots = document.getElementsByClassName("car__dot");

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex++;
    if (slideIndex > slides.length) {
      slideIndex = 1;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" car__active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " car__active";

    if (clicked) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(nextSlide, 6000);
  }

  function previousSlide() {
    var i;
    var slides = document.getElementsByClassName("car__mySlides");
    var dots = document.getElementsByClassName("car__dot");

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    slideIndex--;
    if (slideIndex < 1) {
      slideIndex = slides.length;
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" car__active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " car__active";

    clearTimeout(timeout);
    timeout = setTimeout(nextSlide, 6000);
  }

  var slideIndex = 0;
  var timeout;
  nextSlide();

  const previous = document.getElementById("previous");
  const next = document.getElementById("next");

  next.addEventListener("click", (e) => {
    e.preventDefault();
    nextSlide(true);
  });

  previous.addEventListener("click", (e) => {
    e.preventDefault();
    previousSlide();
  });
}

export { setCarroussel };
