import { useFetch } from "./fetch";
import {
  stripHtml,
  isKeywordInString,
  deleteDuplicate,
  findDuplicates,
  string_to_downcase,
  string_to_slug,
  addItalic,
} from "./utils";
import { renderBlocs } from "./blocs";
/* const axios = require("axios").default; */

function performSearch(category, keywords) {
  keywords.map((keyword) => {
    category.data.map((el) => {
      if (
        isKeywordInString(
          keyword,
          string_to_downcase(el.title || "") +
            string_to_downcase(stripHtml(el.content) || "") +
            el.slug
        )
      )
        category.match.push(el);
    });
  });

  category.match.length > 0 &&
    category.filtred.push(findDuplicates(category.match, keywords.length));

  category.filtred = deleteDuplicate(category.filtred).flat();

  return category.filtred;
}

async function fetchAll(keywordsString) {
  let keywords = keywordsString.split(" ");

  const actualites = {
    data: await useFetch("actualites"),
    match: [],
    filtred: [],
  };
  const formations = {
    data: await useFetch("formations"),
    match: [],
    filtred: [],
  };
  const projets = {
    data: await useFetch("projet-pages"),
    match: [],
    filtred: [],
  };
  const ressources = {
    data: await useFetch("ressource-pages"),
    match: [],
    filtred: [],
  };
  const enLignes = {
    data: await useFetch("ressource-en-lignes"),
    match: [],
    filtred: [],
  };
  const outils = {
    data: await useFetch("ressource-outils"),
    match: [],
    filtred: [],
  };
  const publications = {
    data: await useFetch("ressource-publications"),
    match: [],
    filtred: [],
  };
  const textes = {
    data: await useFetch("ressource-textes"),
    match: [],
    filtred: [],
  };
  const etudes = {
    data: await useFetch("etude-pages"),
    match: [],
    filtred: [],
  };

  let results = {
    actualites: performSearch(actualites, keywords),
    formations: performSearch(formations, keywords),
    projets: performSearch(projets, keywords),
    ressources: performSearch(ressources, keywords),
    enLignes: performSearch(enLignes, keywords),
    outils: performSearch(outils, keywords),
    publications: performSearch(publications, keywords),
    textes: performSearch(textes, keywords),
    etudes: performSearch(etudes, keywords),
  };
  return results;
}

async function renderSearch(input) {
  const $main = document.getElementById("main");
  $main.classList.remove("grid");
  $main.innerHTML = "";
  let items = {
    data: {},
    html: "",
    slug: "",
  };
  let cta = {
    text: "EN SAVOIR PLUS",
    href: "",
  };

  let results = await fetchAll(string_to_downcase(input));
  let blocsHTML = await renderBlocs();

  if (results.actualites.length > 0) {
    items.html += `<h2>Actualités</h2>`;
    results.actualites.map((actualite) => {
      items.html += `<div class="list__item search__item"><a href="/actualites/${
        actualite.slug
      }">${addItalic(actualite.title)}</a><p>${stripHtml(
        actualite.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.formations.length > 0) {
    items.html += `<h2>Formations</h2>`;
    results.formations.map((formation) => {
      items.html += `<div class="list__item search__item"><a href="/formations/${
        formation.slug
      }">${addItalic(formation.title)}</a><p>${stripHtml(
        formation.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.publications.length > 0) {
    items.html += `<h2>Publications</h2>`;
    results.publications.map((publication) => {
      items.html += `<div class="list__item search__item"><a href="/ressources/publications/${
        publication.slug
      }">${addItalic(publication.title)}</a><p>${stripHtml(
        publication.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.projets.length > 0) {
    items.html += `<h2>Projets</h2>`;
    results.projets.map((projet) => {
      items.html += `<div class="list__item search__item"><a href="/projets/${
        projet.slug
      }">${addItalic(projet.title)}</a><p>${stripHtml(projet.content).substring(
        0,
        140
      )} ...</p></div>`;
    });
  }

  if (results.ressources.length > 0) {
    items.html += `<h2>Ressources</h2>`;
    results.ressources.map((ressource) => {
      items.html += `<div class="list__item search__item"><a href="/ressources/${
        ressource.slug
      }">${addItalic(ressource.title)}</a><p>${stripHtml(
        ressource.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.enLignes.length > 0) {
    items.html += `<h2>Ressources en ligne</h2>`;
    results.enLignes.map((enLigne) => {
      items.html += `<div class="list__item search__item"><a href="${
        enLigne.website
      }" target="_blank">${addItalic(enLigne.title)}</a><p>${stripHtml(
        enLigne.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.outils.length > 0) {
    items.html += `<h2>Les outils de l'ANRAT</h2>`;
    results.outils.map((outil) => {
      items.html += `<div class="list__item search__item"><a href="${
        outil.pdf?.url || outil.pdfUrl
      }" target="_blank">${addItalic(outil.title)}</a><p>${stripHtml(
        outil.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.textes.length > 0) {
    items.html += `<h2>Textes de référence</h2>`;
    results.textes.map((texte) => {
      items.html += `<div class="list__item search__item"><a href="${
        texte.pdf?.url || texte.pdfUrl
      }" target="_blank">${addItalic(texte.title)}</a><p>${stripHtml(
        texte.content
      ).substring(0, 140)} ...</p></div>`;
    });
  }

  if (results.etudes.length > 0) {
    items.html += `<h2>Études et recherches</h2>`;
    results.etudes.map((etude) => {
      items.html += `<div class="list__item search__item"><a href="/etudes/${
        etude.slug
      }">${addItalic(etude.title)}</a><p>${stripHtml(etude.content).substring(
        0,
        140
      )} ...</p></div>`;
    });
  }

  /* "actualites[0]", actualites; */
  $main.innerHTML = `<div class="list">
        <div class="title">
          <img src="https://res.cloudinary.com/anrat/image/upload/f_auto/formations_846a20910d.png" alt="Formations">
          <h1>Recherche : ${input}</h1>
        </div>
        ${items.html}
      </div>
  <div class="blocs">
        ${blocsHTML}
      </div>`;
}

export { renderSearch };
