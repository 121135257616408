const actualitesLegacy = [
  {
    legacyId: 544,
    title: "Rencontres internationales de théâtre-éducation, avril 2021.",
    slug: "rencontres-internationales-de-theatre-education-avril-2021",
  },
  {
    legacyId: 543,
    title: "Opération Molière : nouvelles ressources en ligne.",
    slug: "operation-moliere-nouvelles-ressources-en-ligne",
  },
  {
    legacyId: 542,
    title: "INVITATION À L'ASSEMBLÉE GÉNÉRALE DE L'ANRAT - 24 AVRIL  2021",
    slug: "invitation-a-lassemblee-generale-de-lanrat-24-avril-2021",
  },
  {
    legacyId: 541,
    title:
      "FORMATION « Le comité de lecture : modes d’emploi » Mercredi 31 mars, sur zoom De 14h30 à 17h.",
    slug: "formation-le-comite-de-lecture-modes-demploi-mercredi-31-mars-sur-zoom-de-14h30-a-17h",
  },
  {
    legacyId: 540,
    title:
      "Rencontres internationales de théâtre-éducation : 14 – 15 avril 2021, de 14h à 18h30 en distanciel.",
    slug: "rencontres-internationales-de-theatre-education-14-15-avril-2021-de-14h-a-18h30-en-distanciel",
  },
  {
    legacyId: 539,
    title: "OPÉRATION MOLIÈRE - PROCHAINES FORMATIONS AVRIL/MAI 21.",
    slug: "operation-moliere-prochaines-formations-avril-mai-21",
  },
  {
    legacyId: 538,
    title: "CYRANO : un site 100% Théâtre au service des enseignants  !",
    slug: "cyrano-un-site-100-theatre-au-service-des-enseignants-",
  },
  {
    legacyId: 537,
    title: "Opération Molière, nouvelles vidéos en ligne ! ",
    slug: "operation-moliere-nouvelles-videos-en-ligne-",
  },
  {
    legacyId: 536,
    title: 'Appel à textes : "Molière ça t\'inspire ?"',
    slug: "appel-a-textes-moliere-ca-tinspire-",
  },
  {
    legacyId: 535,
    title:
      "Pratiquer le théâtre en portant des masques en cette période de pandémie.",
    slug: "pratiquer-le-theatre-en-portant-des-masques-en-cette-periode-de-pandemie",
  },
  {
    legacyId: 534,
    title: "Opération Molière : formation.",
    slug: "operation-moliere-formation",
  },
  {
    legacyId: 533,
    title: "Ouverture du site operation-moliere.net",
    slug: "ouverture-du-site-operation-molierenet",
  },
  {
    legacyId: 532,
    title: 'DÉFI "LIBÉRONS MOLIÈRE !" ',
    slug: "defi-liberons-moliere-",
  },
  {
    legacyId: 531,
    title: "Vous avez dit culture ?",
    slug: "vous-avez-dit-culture-",
  },
  {
    legacyId: 530,
    title: "Recrutement à la Maison du théâtre d'Amiens.",
    slug: "recrutement-a-la-maison-du-theatre-damiens",
  },
  {
    legacyId: 529,
    title: "Atelier autour de l'alexandrin chez Molière ",
    slug: "atelier-autour-de-lalexandrin-chez-moliere",
  },
  {
    legacyId: 528,
    title: "Comment mener un comité de lecture de théâtre, avec quels outils ?",
    slug: "comment-mener-un-comite-de-lecture-de-theatre-avec-quels-outils-",
  },
  {
    legacyId: 495,
    title:
      "Ouverture de l’action nationale d’éducation artistique Théâ 2020/2021",
    slug: "ouverture-de-laction-nationale-deducation-artistique-thea-2020-2021",
  },
  {
    legacyId: 493,
    title:
      "Le pire n'est pas toujours sûr ! - formation autour de la captation, à partir du Soulier de satin",
    slug: "le-pire-nest-pas-toujours-sur-formation-autour-de-la-captation-a-partir-du-soulier-de-satin",
  },
  {
    legacyId: 492,
    title: "Un Rêve d’Avignon...",
    slug: "un-reve-davignon",
  },
  {
    legacyId: 489,
    title:
      "Entre école et spectacle vivant, l'Éducation Artistique et Culturelle est la solution ! - 19 juin 2020",
    slug: "entre-ecole-et-spectacle-vivant-leducation-artistique-et-culturelle-est-la-solution-19-juin-2020",
  },
  {
    legacyId: 488,
    title:
      "Inscrire plus que jamais le spectacle vivant au cœur de l’éducation artistique et culturelle - 9 juin 2020.",
    slug: "inscrire-plus-que-jamais-le-spectacle-vivant-au-cur-de-leducation-artistique-et-culturelle-9-juin-2020",
  },
  {
    legacyId: 487,
    title: "Hommage à Jean-Noël Roy, réalisateur de télévision",
    slug: "hommage-a-jean-noel-roy-realisateur-de-television",
  },
  {
    legacyId: 486,
    title: "LE SÉMINAIRE DE L'ANRAT AU FESTIVAL D'AVIGNON",
    slug: "le-seminaire-de-lanrat-au-festival-davignon",
  },
  {
    legacyId: 485,
    title:
      "APPEL À PROJETS 2020-2022 « Artistes à l’École » - jusqu'au 1er juin 2020",
    slug: "appel-a-projets-2020-2022-artistes-a-lecole-jusquau-1er-juin-2020",
  },
  {
    legacyId: 484,
    title:
      "Le 27 mars, les enfants du siècle prennent la parole - édition 2020",
    slug: "le-27-mars-les-enfants-du-siecle-prennent-la-parole-edition-2020",
  },
  {
    legacyId: 483,
    title: "Thealingua - les langues étrangères par le théâtre",
    slug: "thealingua-les-langues-etrangeres-par-le-theatre",
  },
  {
    legacyId: 482,
    title:
      "Conférence sur le théâtre d'objets le 28 novembre 2019 au Mouffetard-théâtre des arts de la marionnette",
    slug: "conference-sur-le-theatre-dobjets-le-28-novembre-2019-au-mouffetard-theatre-des-arts-de-la-marionnette",
  },
  {
    legacyId: 481,
    title:
      "Le projet international de coopération artistique Play!MOBILE arrive dans l'Oise.",
    slug: "le-projet-international-de-cooperation-artistique-playmobile-arrive-dans-loise",
  },
  {
    legacyId: 480,
    title:
      "10 ans Oh Boy ! - Evénements, ateliers, débats, rencontres gratuits au MONFORT",
    slug: "10-ans-oh-boy-evenements-ateliers-debats-rencontres-gratuits-au-monfort",
  },
  {
    legacyId: 478,
    title: "OFFRE D'EMPLOI - Institut international de la marionnette",
    slug: "offre-demploi-institut-international-de-la-marionnette",
  },
  {
    legacyId: 477,
    title:
      "Journée d’étude « Secteur privé, service public: les avatars d’un couple notionnel.  L’exemple du théâtre français du XVIIIe au XXIe siècle » .",
    slug: "journee-detude-secteur-prive-service-public-les-avatars-dun-couple-notionnel-lexemple-du-theatre-francais-du-xviiie-au-xxie-siecle-",
  },
  {
    legacyId: 476,
    title:
      "COLLOQUE INTERNATIONAL – L’ENSEIGNEMENT DU THÉÂTRE « Comment (re)penser l’enseignement du théâtre dans les différents contextes d’éducation ? » Grenoble du 6 au 8 novembre 2019.",
    slug: "colloque-international-lenseignement-du-theatre-comment-repenser-lenseignement-du-theatre-dans-les-differents-contextes-deducation-grenoble-du-6-au-8-novembre-2019",
  },
  {
    legacyId: 475,
    title: "Rencontre de l'ANRAT à Avignon",
    slug: "rencontre-de-lanrat-a-avignon",
  },
  {
    legacyId: 474,
    title:
      "31 èmes Rencontres du Jeune Théâtre Européen, du 28 juin au 7 juillet 2019 à Grenoble.",
    slug: "31-emes-rencontres-du-jeune-theatre-europeen-du-28-juin-au-7-juillet-2019-a-grenoble",
  },
  {
    legacyId: 473,
    title:
      'L’acte III des "Rencontres de l’ANRAT" - mardi 4 juin au Nouveau théâtre de Montreuil.',
    slug: "lacte-iii-des-rencontres-de-lanrat-mardi-4-juin-au-nouveau-theatre-de-montreuil",
  },
  {
    legacyId: 472,
    title: "OFFRE D'EMPLOI",
    slug: "offre-demploi",
  },
  {
    legacyId: 471,
    title: "Compléments de formations - Nouvelles vidéos en ligne",
    slug: "complements-de-formations-nouvelles-videos-en-ligne",
  },
  {
    legacyId: 470,
    title:
      "Les rencontres de l'ANRAT - Vidéo de l'acte I autour de Kanata-Épisode I-La Controverse",
    slug: "les-rencontres-de-lanrat-video-de-lacte-i-autour-de-kanata-episode-i-la-controverse",
  },
  {
    legacyId: 469,
    title:
      "Les rencontres de l'ANRAT 2019 : Création et (auto)censure, questions posées aux artistes et aux enseignants.",
    slug: "les-rencontres-de-lanrat-2019-creation-et-autocensure-questions-posees-aux-artistes-et-aux-enseignants",
  },
  {
    legacyId: 468,
    title:
      "Alerte sur la place assignée au théâtre dans le nouveau lycée : pour une autre ambition",
    slug: "alerte-sur-la-place-assignee-au-theatre-dans-le-nouveau-lycee-pour-une-autre-ambition",
  },
  {
    legacyId: 467,
    title:
      "Les rencontres de l'ANRAT 2019 : « Création et (auto)censure, questions posées aux artistes et aux enseignants ».",
    slug: "les-rencontres-de-lanrat-2019-creation-et-autocensure-questions-posees-aux-artistes-et-aux-enseignants-",
  },
  {
    legacyId: 466,
    title: "SÉMINAIRE 2019 DE L'ANRAT À AVIGNON",
    slug: "seminaire-2019-de-lanrat-a-avignon",
  },
  {
    legacyId: 465,
    title: "Candidature au conseil d'administration de l'ANRAT 2021",
    slug: "candidature-au-conseil-dadministration-de-lanrat-2021",
  },
  {
    legacyId: 464,
    title: "FORMATIONS ANRAT 2019 - INSCRIPTIONS.",
    slug: "formations-anrat-2019-inscriptions",
  },
  {
    legacyId: 463,
    title: "Enseignements de spécialité Théâtre.",
    slug: "enseignements-de-specialite-theatre",
  },
  {
    legacyId: 461,
    title: "Qui veut sauver le texte dans le théâtre jeune public ?",
    slug: "qui-veut-sauver-le-texte-dans-le-theatre-jeune-public-",
  },
  {
    legacyId: 460,
    title:
      "Comment (re)penser l’enseignement du théâtre dans les différents contextes d’éducation ?",
    slug: "comment-repenser-lenseignement-du-theatre-dans-les-differents-contextes-deducation-",
  },
  {
    legacyId: 459,
    title: "« C’est quoi le spectacle dans la vie d’un enfant ? »",
    slug: "-cest-quoi-le-spectacle-dans-la-vie-dun-enfant-",
  },
  {
    legacyId: 458,
    title: "Adhésion 2018-2019",
    slug: "adhesion-2018-2019",
  },
  {
    legacyId: 457,
    title: "Loup, qui es-tu ? ",
    slug: "loup-qui-es-tu-",
  },
  {
    legacyId: 456,
    title: "Festival des écoles du théâtre public 2018",
    slug: "festival-des-ecoles-du-theatre-public-2018",
  },
  {
    legacyId: 455,
    title: "Rendez-vous avec la jeunesse au Festival d'Avignon ",
    slug: "rendez-vous-avec-la-jeunesse-au-festival-davignon",
  },
  {
    legacyId: 454,
    title:
      '"The Backpack Twelve" la troupe du lycée français de Pointe Noire au Congo',
    slug: "the-backpack-twelve-la-troupe-du-lycee-francais-de-pointe-noire-au-congo",
  },
  {
    legacyId: 453,
    title: "Rencontres théâtrales et chorégraphiques d’enfants",
    slug: "rencontres-theatrales-et-choregraphiques-denfants",
  },
  {
    legacyId: 452,
    title:
      "Retour en images sur la formation autour des Écritures Théâtrales Jeunesse",
    slug: "retour-en-images-sur-la-formation-autour-des-ecritures-theatrales-jeunesse",
  },
  {
    legacyId: 451,
    title: "« LIRECRIRE »  en milieu scolaire",
    slug: "-lirecrire-en-milieu-scolaire",
  },
  {
    legacyId: 450,
    title: "MOA  à l'école ?",
    slug: "moa-a-lecole-",
  },
  {
    legacyId: 449,
    title: "Les Hauts Parleurs #3 – collectif à mots découverts              ",
    slug: "les-hauts-parleurs-3-collectif-a-mots-decouverts",
  },
  {
    legacyId: 448,
    title: "Les journées du théâtre lycéen - 10ème édition",
    slug: "les-journees-du-theatre-lyceen-10eme-edition",
  },
  {
    legacyId: 447,
    title: "Appel à participation",
    slug: "appel-a-participation",
  },
  {
    legacyId: 446,
    title:
      "Rendez-vous au TNG de Lyon pour le 1er juin des Écritures Théâtrales Jeunesse",
    slug: "rendez-vous-au-tng-de-lyon-pour-le-1er-juin-des-ecritures-theatrales-jeunesse",
  },
  {
    legacyId: 445,
    title: "RAPPORT MORAL exercice 2017",
    slug: "rapport-moral-exercice-2017",
  },
  {
    legacyId: 444,
    title: "ATTENTION Inscription à notre lettre d'information",
    slug: "attention-inscription-a-notre-lettre-dinformation",
  },
  {
    legacyId: 443,
    title: "Les 10 ans du prix ADO",
    slug: "les-10-ans-du-prix-ado",
  },
  {
    legacyId: 442,
    title: "La Maison du geste et de l'image accompagne les projets 2018-2019",
    slug: "la-maison-du-geste-et-de-limage-accompagne-les-projets-2018-2019",
  },
  {
    legacyId: 441,
    title: "Pourquoi ça s'appelle pas Néron ?",
    slug: "pourquoi-ca-sappelle-pas-neron-",
  },
  {
    legacyId: 440,
    title: "« Jean-Luc », de la scène au livre",
    slug: "jean-luc-de-la-scene-au-livre",
  },
  {
    legacyId: 439,
    title: "Commission des affaires culturelles",
    slug: "commission-des-affaires-culturelles",
  },
  {
    legacyId: 438,
    title: "Ouverture du site Théâtre en acte",
    slug: "ouverture-du-site-theatre-en-acte",
  },
  {
    legacyId: 437,
    title: "Un avant-goût du stage de l'ANRAT 2018.",
    slug: "un-avant-gout-du-stage-de-lanrat-2018",
  },
  {
    legacyId: 436,
    title:
      "La fin du dispositif Transvers’Arts et la floraison de nouveaux projets.",
    slug: "la-fin-du-dispositif-transversarts-et-la-floraison-de-nouveaux-projets",
  },
  {
    legacyId: 435,
    title: "Convocation à l'Assemblée Générale du 7 avril 2018",
    slug: "convocation-a-lassemblee-generale-du-7-avril-2018",
  },
  {
    legacyId: 434,
    title: "L'ardeur du printemps",
    slug: "lardeur-du-printemps",
  },
  {
    legacyId: 433,
    title: "La décentralisation théâtrale en 1968",
    slug: "la-decentralisationtheatrale-en-1968",
  },
  {
    legacyId: 432,
    title:
      "Journée expérimentale autour de l'éducation artistique et culturelle",
    slug: "journee-experimentale-autour-de-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 431,
    title:
      '"C\'est pas pour les petits !?" Le texte en-jeu(x) dans le théâtre très jeune public.',
    slug: "cest-pas-pour-les-petits-le-texte-en-jeux-dans-le-theatre-tres-jeune-public",
  },
  {
    legacyId: 430,
    title: "La dramaturgie dans les écritures de plateau",
    slug: "la-dramaturgie-dans-les-ecritures-de-plateau",
  },
  {
    legacyId: 429,
    title: "#La Scène aux Ados 8 - Appel à projets",
    slug: "la-scene-aux-ados-8-appel-a-projets",
  },
  {
    legacyId: 428,
    title: "L'option théâtre c'est quoi ? ",
    slug: "loption-theatre-cest-quoi-",
  },
  {
    legacyId: 427,
    title: "Résultats de recherche #3 du LABO ANRAT",
    slug: "resultats-de-recherche-3-du-labo-anrat",
  },
  {
    legacyId: 426,
    title: "De l'exigence de la maîtrise du français",
    slug: "de-lexigence-de-la-maitrise-du-francais",
  },
  {
    legacyId: 425,
    title: "Séminaire international sur « Le rôle social du théâtre »",
    slug: "seminaire-international-sur-le-role-social-du-theatre-",
  },
  {
    legacyId: 424,
    title: "FILM DOCUMENTAIRE - Blanche Rhapsodie de Claire Ruppli",
    slug: "film-documentaire-blanche-rhapsodie-de-claire-ruppli",
  },
  {
    legacyId: 423,
    title: "L'art et la manière d'intervenir en milieu scolaire",
    slug: "lart-et-la-maniere-dintervenir-en-milieu-scolaire",
  },
  {
    legacyId: 422,
    title: "L'INVITATION AU SPECTACLE 2018 EN HAUTS-DE-FRANCE",
    slug: "linvitation-au-spectacle-2018-en-hauts-de-france",
  },
  {
    legacyId: 421,
    title: "Festival IMPATIENCE - Le Prix des lycéens 2017",
    slug: "festival-impatience-le-prix-des-lyceens-2017",
  },
  {
    legacyId: 420,
    title: 'COMPTE-RENDU : Les Actes des Assises "Théâtre-éducation"',
    slug: "compte-rendu-les-actes-des-assises-theatre-education",
  },
  {
    legacyId: 419,
    title: "Vers un projet culturel commun en Mayenne",
    slug: "vers-un-projet-culturel-commun-en-mayenne",
  },
  {
    legacyId: 418,
    title:
      "Action culturelle entre Le Lycée Baudelaire et le Théâtre du Nord, à Tourcoing.",
    slug: "action-culturelle-entre-le-lycee-baudelaire-et-le-theatre-du-nord-a-tourcoing",
  },
  {
    legacyId: 417,
    title:
      "Action culturelle menée par le Théâtre dans les Vignes à Couffoulens dans l'Aude.",
    slug: "action-culturelle-menee-par-le-theatre-dans-les-vignes-a-couffoulens-dans-laude",
  },
  {
    legacyId: 416,
    title: "Résultats de recherche #2 du LABO ANRAT",
    slug: "resultats-de-recherche-2-du-labo-anrat",
  },
  {
    legacyId: 415,
    title: "Hommage à Jacques Lassalle",
    slug: "hommage-a-jacques-lassalle",
  },
  {
    legacyId: 414,
    title: "Les lundis de Gaston Baty",
    slug: "les-lundis-de-gaston-baty",
  },
  {
    legacyId: 413,
    title:
      "Compte-rendu du temps fort dédié à la jeunesse organisé par le Théâtre Dijon-Bourgogne - CDN",
    slug: "compte-rendu-du-temps-fort-dedie-a-la-jeunesse-organise-par-le-theatre-dijon-bourgogne-cdn",
  },
  {
    legacyId: 412,
    title: "Joyeux anniversaire COMÈTE !",
    slug: "joyeux-anniversaire-comete-",
  },
  {
    legacyId: 411,
    title: "Appel aux dons",
    slug: "appel-aux-dons",
  },
  {
    legacyId: 410,
    title: "Film-documentaire : Une aventure théâtrale",
    slug: "film-documentaire-une-aventure-theatrale",
  },
  {
    legacyId: 409,
    title: "Hauts de France : résidences-mission du programme C.L.E.A",
    slug: "hauts-de-france-residences-mission-du-programme-clea",
  },
  {
    legacyId: 407,
    title:
      "Françoise Nyssen et Jean-Michel Blanquer annoncent 20 millions d'euros pour la musique à l'école.",
    slug: "francoise-nyssen-et-jean-michel-blanquer-annoncent-20-millions-deuros-pour-la-musique-a-lecole",
  },
  {
    legacyId: 406,
    title: "Résultats de recherche #1 du LABO ANRAT",
    slug: "resultats-de-recherche-1-du-labo-anrat",
  },
  {
    legacyId: 404,
    title: "Quels rôles pour la pratique du théâtre avec des artistes ?",
    slug: "quels-roles-pour-la-pratique-du-theatre-avec-des-artistes-",
  },
  {
    legacyId: 403,
    title:
      "IThAC menacée de disparition ou l'éducation au théâtre en péril en Belgique",
    slug: "ithac-menacee-de-disparition-ou-leducation-au-theatre-en-peril-en-belgique",
  },
  {
    legacyId: 402,
    title:
      "Le programme de la transmission à l'École de La Compagnie des Lucioles.",
    slug: "le-programme-de-la-transmission-a-lecole-de-la-compagnie-des-lucioles",
  },
  {
    legacyId: 401,
    title:
      "Nos conseils de lecture parmi les éditions de l'Attribut pour un abonnement à NECTART.",
    slug: "nos-conseils-de-lecture-parmi-les-editions-de-lattribut-pour-un-abonnement-anectart",
  },
  {
    legacyId: 400,
    title:
      "Appel à contribution avant le 10 décembre dans le cadre du projet « Création & Crise »",
    slug: "appel-a-contribution-avant-le-10-decembre-dans-le-cadre-du-projet-creation-crise",
  },
  {
    legacyId: 399,
    title: "Hommage à Jack Ralite",
    slug: "hommage-a-jack-ralite",
  },
  {
    legacyId: 398,
    title:
      "L'ANRAT, partenaire du Théâtre Dijon Bourgogne pour un temps fort dédié à la jeunesse.",
    slug: "lanrat-partenaire-du-theatre-dijon-bourgogne-pour-un-temps-fort-dedie-a-la-jeunesse",
  },
  {
    legacyId: 397,
    title:
      'L\'ANRAT, partenaire du film "À nous de jouer !" de Antoine Fromental qui sort le 8 novembre.',
    slug: "lanrat-partenaire-du-film-a-nous-de-jouer-de-antoine-fromental-qui-sort-le-8-novembre",
  },
  {
    legacyId: 396,
    title:
      "Grand Prix de la littérature dramatique et de littérature dramatique Jeunesse 2017",
    slug: "grand-prix-de-la-litterature-dramatique-et-de-litterature-dramatique-jeunesse-2017",
  },
  {
    legacyId: 395,
    title: 'Label "centre national de la marionnette"',
    slug: "label-centre-national-de-la-marionnette",
  },
  {
    legacyId: 394,
    title:
      "Galerie d'illustration et collection dédiée à la création théâtrale",
    slug: "galerie-dillustration-et-collection-dediee-a-la-creation-theatrale",
  },
  {
    legacyId: 393,
    title:
      "Appel à candidatures > résidences-missions à Clichy-sous-Bois et Montfermeil / CLÉA",
    slug: "appel-a-candidatures-residences-missions-a-clichy-sous-bois-et-montfermeil-clea",
  },
  {
    legacyId: 392,
    title: "Diplôme d'Etat de professeur de théâtre ",
    slug: "diplome-detat-de-professeur-de-theatre",
  },
  {
    legacyId: 391,
    title: "Appel à candidature > résidence d'artistes à Saint-Chamond",
    slug: "appel-a-candidature-residence-dartistes-a-saint-chamond",
  },
  {
    legacyId: 390,
    title:
      "Éducation artistique et culturelle : la détermination de Jean-Michel Blanquer, ministre de l'Éducation nationale",
    slug: "education-artistique-et-culturelle-la-determination-de-jean-michel-blanquer-ministre-de-leducation-nationale",
  },
  {
    legacyId: 388,
    title: "Suspension du prix de l’inédiThéâtre",
    slug: "suspension-du-prix-de-lineditheatre",
  },
  {
    legacyId: 387,
    title: "Fiestaaaaaaaaaaaaaaaaaaaaa !!! chez Très Tôt Théâtre",
    slug: "fiestaaaaaaaaaaaaaaaaaaaaa-chez-tres-tot-theatre",
  },
  {
    legacyId: 386,
    title:
      "Message de Robin Renucci au Haut-Conseil pour l'éducation artistique et culturelle",
    slug: "message-de-robin-renucci-au-haut-conseil-pour-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 385,
    title:
      "Communication en conseil des ministres : l'éducation artistique et culturelle",
    slug: "communication-en-conseil-des-ministres-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 384,
    title: "Prix Collidram 2018",
    slug: "prix-collidram-2018",
  },
  {
    legacyId: 383,
    title: "Les actions 2017-2018 de Théâtre à la Page ",
    slug: "les-actions-2017-2018-de-theatre-a-la-page",
  },
  {
    legacyId: 382,
    title: "Les Mardis Midi - Quand l'auteur rencontre le metteur en scène",
    slug: "les-mardis-midi-quand-lauteur-rencontre-le-metteur-en-scene",
  },
  {
    legacyId: 381,
    title: "Quelle diversité culturelle sur les scènes européennes ?",
    slug: "quelle-diversite-culturelle-sur-les-scenes-europeennes-",
  },
  {
    legacyId: 380,
    title:
      "Encore quelques places disponibles pour Les enfants du patrimoine 2017",
    slug: "encore-quelques-places-disponibles-pour-les-enfants-du-patrimoine-2017",
  },
  {
    legacyId: 379,
    title: "Audition des ministres de la Culture et de l'Éducation nationale",
    slug: "audition-des-ministres-de-la-culture-et-de-leducation-nationale",
  },
  {
    legacyId: 378,
    title: "Appel à contributions du Parlement éphémère Grand Est",
    slug: "appel-a-contributions-du-parlement-ephemere-grand-est",
  },
  {
    legacyId: 377,
    title:
      "Campagne de recrutement aux métiers des arts du spectacle pour les jeunes de 18 à 26 ans",
    slug: "campagne-de-recrutement-aux-metiers-des-arts-du-spectacle-pour-les-jeunes-de-18-a-26-ans",
  },
  {
    legacyId: 376,
    title:
      "Rythmes scolaires et éducation artistique et culturelle : pour une concertation nationale",
    slug: "rythmes-scolaires-et-education-artistique-et-culturelle-pour-une-concertation-nationale",
  },
  {
    legacyId: 375,
    title: "Appel à candidatures > La caravane des dix mots",
    slug: "appel-a-candidatures-la-caravane-des-dix-mots",
  },
  {
    legacyId: 374,
    title: "Appel à participation > C'est pour bientôt",
    slug: "appel-a-participation-cest-pour-bientot",
  },
  {
    legacyId: 373,
    title: "Appel à participation > dossier spécial création",
    slug: "appel-a-participation-dossier-special-creation",
  },
  {
    legacyId: 372,
    title: "Deux prix littéraires réunionnais",
    slug: "deux-prix-litteraires-reunionnais",
  },
  {
    legacyId: 371,
    title: "Rencontres Jeunes Publics et Francophonie",
    slug: "rencontres-jeunes-publics-et-francophonie",
  },
  {
    legacyId: 370,
    title:
      "Le jeu des 7 familles : théâtre multilingue au Théâtre Paul Eluard de Choisy-le-Roi",
    slug: "le-jeu-des-7-familles-theatre-multilingue-au-theatre-paul-eluard-de-choisy-le-roi",
  },
  {
    legacyId: 369,
    title: "Romans Tout-Monde : créolisation des langues",
    slug: "romans-tout-monde-creolisation-des-langues",
  },
  {
    legacyId: 368,
    title:
      "Promouvoir la diversité dans le secteur culture : Livre blanc du Collège de la Diversité",
    slug: "promouvoir-la-diversite-dans-le-secteur-culture-livre-blanc-du-college-de-la-diversite",
  },
  {
    legacyId: 367,
    title:
      "Le roman, une voie vers la découverte des théâtres africains et haïtiens",
    slug: "le-roman-une-voie-vers-la-decouverte-des-theatres-africains-et-haitiens",
  },
  {
    legacyId: 366,
    title: "Afrithéâtre, théâtres contemporains du monde noir francophone",
    slug: "afritheatre-theatres-contemporains-du-monde-noir-francophone",
  },
  {
    legacyId: 365,
    title:
      "Parité et diversité, deux versants des minorités dites visibles dans les arts vivants ?",
    slug: "parite-et-diversite-deux-versants-des-minorites-dites-visibles-dans-les-arts-vivants-",
  },
  {
    legacyId: 364,
    title: "Changer les imaginaires",
    slug: "changer-les-imaginaires",
  },
  {
    legacyId: 363,
    title:
      "De la liberté à l'égalité. De nouveaux enjeux pour l'enseignement du théâtre et la formation de l'acteur",
    slug: "de-la-liberte-a-legalite-de-nouveaux-enjeux-pour-lenseignement-du-theatre-et-la-formation-de-lacteur",
  },
  {
    legacyId: 362,
    title: "Jeunes textes en liberté",
    slug: "jeunes-textes-en-liberte",
  },
  {
    legacyId: 361,
    title: "Ode au dissensus",
    slug: "ode-au-dissensus",
  },
  {
    legacyId: 360,
    title: "Prix de l'inédiTs d'Afrique et Outremer",
    slug: "prix-de-linedits-dafrique-et-outremer",
  },
  {
    legacyId: 359,
    title: "Compte rendu de la rencontre sur les publics adolescents ",
    slug: "compte-rendu-de-la-rencontre-sur-les-publics-adolescents",
  },
  {
    legacyId: 358,
    title: "L'éducation artistique et culturelle : et maintenant ?",
    slug: "leducation-artistique-et-culturelle-et-maintenant-",
  },
  {
    legacyId: 357,
    title: "11ème Prix E.A.T. des Cent Livres Emmanuelle-Marie",
    slug: "11eme-prix-eat-des-cent-livres-emmanuelle-marie",
  },
  {
    legacyId: 356,
    title: "Appel à contribution > corps, image, éducation",
    slug: "appel-a-contribution-corps-image-education",
  },
  {
    legacyId: 355,
    title:
      "Journée d'étude Éducation artistique et culturelle. Des publics pour la danse",
    slug: "journee-detude-education-artistique-et-culturelle-des-publics-pour-la-danse",
  },
  {
    legacyId: 354,
    title: "Les Coups de Coeur de l'année de Théâtre À la Page ",
    slug: "les-coups-de-coeur-de-lannee-de-theatre-a-la-page",
  },
  {
    legacyId: 353,
    title: "L'ANRAT au secours des options Théâtre",
    slug: "lanrat-au-secours-des-options-theatre",
  },
  {
    legacyId: 352,
    title: "Belle fête du 1er Juin ! signé Sylvain Levey",
    slug: "belle-fete-du-1er-juin-signe-sylvain-levey",
  },
  {
    legacyId: 351,
    title:
      "Théâtre à l'école en temps scolaire, un projet expérimental d'Amiens Métropole",
    slug: "theatre-a-lecole-en-temps-scolaire-un-projet-experimental-damiens-metropole",
  },
  {
    legacyId: 350,
    title:
      "Égalité dans le travail au plateau, transmission du théâtre, accès égalitaire à l'apprentissage des arts...",
    slug: "egalite-dans-le-travail-au-plateau-transmission-du-theatre-acces-egalitaire-a-lapprentissage-des-arts",
  },
  {
    legacyId: 349,
    title:
      "Théâtre en livres, un festival de lectures-rencontres en librairies",
    slug: "theatre-en-livres-un-festival-de-lectures-rencontres-en-librairies",
  },
  {
    legacyId: 348,
    title: "Rencontres nationales THÉÂ et son Forum",
    slug: "rencontres-nationales-thea-et-son-forum",
  },
  {
    legacyId: 347,
    title:
      "Les prochains rendez-vous du Collectif Jeune Public des Hauts-de-France",
    slug: "les-prochains-rendez-vous-du-collectif-jeune-public-des-hauts-de-france",
  },
  {
    legacyId: 346,
    title: "Lectures / Postures - Les Belles Écritures",
    slug: "lectures-postures-les-belles-ecritures",
  },
  {
    legacyId: 345,
    title: "Théâtre-éducation et réussite scolaire",
    slug: "theatre-education-et-reussite-scolaire",
  },
  {
    legacyId: 344,
    title:
      "Festival Horizons nécessaires - Le Scénoscope s'invite au Théâtre de la Cité !",
    slug: "festival-horizons-necessaires-le-scenoscope-sinvite-au-theatre-de-la-cite-",
  },
  {
    legacyId: 343,
    title:
      "Le Petit Colossal Théâtre se met en scène en mai, juin et juillet !",
    slug: "le-petit-colossal-theatre-se-met-en-scene-en-mai-juin-et-juillet-",
  },
  {
    legacyId: 342,
    title: "Éducation et frontières",
    slug: "education-et-frontieres",
  },
  {
    legacyId: 341,
    title: "InédiTs d'Afrique et Outremer 2017",
    slug: "inedits-dafrique-et-outremer-2017",
  },
  {
    legacyId: 340,
    title: "Inédits d'Afrique et outremer",
    slug: "inedits-dafrique-et-outremer",
  },
  {
    legacyId: 339,
    title: "Rencontres arts et coopération à l'école et ailleurs",
    slug: "rencontres-arts-et-cooperation-a-lecole-et-ailleurs",
  },
  {
    legacyId: 338,
    title: "Restitution des ateliers LirEcrire en milieu scolaire",
    slug: "restitution-des-ateliers-lirecrire-en-milieu-scolaire",
  },
  {
    legacyId: 337,
    title: "Pétition Sauvons les options artistiques ",
    slug: "petition-sauvons-les-options-artistiques",
  },
  {
    legacyId: 336,
    title: "11ème édition du Prix Collidram",
    slug: "11eme-edition-du-prix-collidram",
  },
  {
    legacyId: 335,
    title: "Les rencontres de théâtre amateur - Les Téméraires 2017",
    slug: "les-rencontres-de-theatre-amateur-les-temeraires-2017",
  },
  {
    legacyId: 334,
    title:
      "Appel à projets de création 2017-2018 pour jeunes artistes > Prémisses Production ",
    slug: "appel-a-projets-de-creation-2017-2018-pour-jeunes-artistes-premisses-production",
  },
  {
    legacyId: 333,
    title:
      "Hommage à Vincent Cambier, fondateur et rédacteur en chef des Trois Coups",
    slug: "hommage-a-vincent-cambier-fondateur-et-redacteur-en-chef-des-trois-coups",
  },
  {
    legacyId: 332,
    title: "Grande Collecte de Petites Histoires",
    slug: "grande-collecte-de-petites-histoires",
  },
  {
    legacyId: 331,
    title: "Partager le théâtre contemporain jeunesse à l'école...",
    slug: "partager-le-theatre-contemporain-jeunesse-a-lecole",
  },
  {
    legacyId: 330,
    title: "Conférence de presse du 71e Festival d'Avignon",
    slug: "conference-de-presse-du-71e-festival-davignon",
  },
  {
    legacyId: 329,
    title: "Les séjours pour les jeunes au Festival d'Avignon avec les Ceméa",
    slug: "les-sejours-pour-les-jeunes-au-festival-davignon-avec-les-cemea",
  },
  {
    legacyId: 328,
    title:
      "La recette de Sylvain Levey pour un 1er Juin des écritures théâtrales jeunesse réussi !",
    slug: "la-recette-de-sylvain-levey-pour-un-1er-juin-des-ecritures-theatrales-jeunesse-reussi-",
  },
  {
    legacyId: 327,
    title: "Recherche dessinateur pour créer un film d'animation",
    slug: "recherche-dessinateur-pour-creer-un-film-danimation",
  },
  {
    legacyId: 326,
    title:
      "Itinéraires singuliers, l'art et l'expression dans la lutte contre les exclusions",
    slug: "itineraires-singuliers-lart-et-lexpression-dans-la-lutte-contre-les-exclusions",
  },
  {
    legacyId: 325,
    title: "L'InédiThéâtre 2017 - Prix lycéen de pièces inédites",
    slug: "lineditheatre-2017-prix-lyceen-de-pieces-inedites",
  },
  {
    legacyId: 324,
    title: "Deux événements à ne pas manquer à l'Université de Cergy",
    slug: "deux-evenements-a-ne-pas-manquer-a-luniversite-de-cergy",
  },
  {
    legacyId: 323,
    title:
      'Remise du Prix Jean-Paul Alègre lauréat 2017 du Prix ado du théâtre contemporain avec la pièce "Moi, Ota, rivière d\'Hiroshima"',
    slug: "remise-du-prix-jean-paul-alegre-laureat-2017-du-prix-ado-du-theatre-contemporain-avec-la-piece-moi-ota-riviere-dhiroshima",
  },
  {
    legacyId: 322,
    title:
      "Appel à candidatures > offres de résidences-mission Drac Hauts-de-France",
    slug: "appel-a-candidatures-offres-de-residences-mission-drac-hauts-de-france",
  },
  {
    legacyId: 321,
    title: "Projet Esprit critique de l'association SynLab",
    slug: "projet-esprit-critique-de-lassociation-synlab",
  },
  {
    legacyId: 320,
    title: "Festival La cour aux ados 04 > 08 avril au Théâtre du Pélican",
    slug: "festival-la-cour-aux-ados-04-08-avril-au-theatre-du-pelican",
  },
  {
    legacyId: 319,
    title: 'Forum politique "Arts vivants, enfance et jeunese"',
    slug: "forum-politique-arts-vivants-enfance-et-jeunese",
  },
  {
    legacyId: 318,
    title:
      "Invitation > Horizons nécessaires, un événement culturel parrainé par le Théâtre de la Cité Internationale",
    slug: "invitation-horizons-necessaires-un-evenement-culturel-parraine-par-le-theatre-de-la-cite-internationale",
  },
  {
    legacyId: 317,
    title:
      "Un protocole pour favoriser l'éveil artistique et culturel du jeune enfant",
    slug: "un-protocole-pour-favoriser-leveil-artistique-et-culturel-du-jeune-enfant",
  },
  {
    legacyId: 316,
    title: "Pour la jeunesse, la culture organisée",
    slug: "pour-la-jeunesse-la-culture-organisee",
  },
  {
    legacyId: 315,
    title:
      "Rencontre > Les artistes iront à toi : De la présence du spectacle vivant jeune public dans les territoires ruraux",
    slug: "rencontre-les-artistes-iront-a-toi-de-la-presence-du-spectacle-vivant-jeune-public-dans-les-territoires-ruraux",
  },
  {
    legacyId: 314,
    title: "P(art)AGER : accompagner l'enfant dans sa découverte du spectacle",
    slug: "partager-accompagner-lenfant-dans-sa-decouverte-du-spectacle",
  },
  {
    legacyId: 313,
    title:
      "Manifeste > Pour une véritable politique artistique et culturelle de l'enfance et de la jeunesse",
    slug: "manifeste-pour-une-veritable-politique-artistique-et-culturelle-de-lenfance-et-de-la-jeunesse",
  },
  {
    legacyId: 312,
    title:
      "L'intermède, un journal sur l'art et la culture associant chercheurs et journalistes",
    slug: "lintermede-un-journal-sur-lart-et-la-culture-associant-chercheurs-et-journalistes",
  },
  {
    legacyId: 311,
    title:
      "Territoires d'éveil, la lettre des acteurs de l'éveil culturel et artistitique du jeune",
    slug: "territoires-deveil-la-lettre-des-acteurs-de-leveil-culturel-et-artistitique-du-jeune",
  },
  {
    legacyId: 310,
    title: 'Appels à projets Réseau Canopé "Arts et Culture"',
    slug: "appels-a-projets-reseau-canope-arts-et-culture",
  },
  {
    legacyId: 309,
    title:
      "Pourquoi le théâtre, l'art en général sont-ils si importants pour les populations les plus fragiles ?",
    slug: "pourquoi-le-theatre-lart-en-general-sont-ils-si-importants-pour-les-populations-les-plus-fragiles-",
  },
  {
    legacyId: 308,
    title:
      "Table ronde \"De la commande de texte de théâtre à l'auteur, suivi du spectacle-conférence de Nathalie Papin",
    slug: "table-ronde-de-la-commande-de-texte-de-theatre-a-lauteur-suivi-du-spectacle-conference-de-nathalie-papin",
  },
  {
    legacyId: 307,
    title: "Assemblée Générale de l'ANRAT 2017",
    slug: "assemblee-generale-de-lanrat-2017",
  },
  {
    legacyId: 306,
    title:
      "Futur.e.s participant.e.s au 1er Juin des écritures théâtrales jeunesse, faites-vous connaître !",
    slug: "futures-participantes-au-1er-juin-des-ecritures-theatrales-jeunesse-faites-vous-connaitre-",
  },
  {
    legacyId: 305,
    title: "Forum politique Arts vivants, enfance et jeunesse",
    slug: "forum-politique-arts-vivants-enfance-et-jeunesse",
  },
  {
    legacyId: 304,
    title: "Le Pass culture en Pays de la Loire en mauvaise passe",
    slug: "le-pass-culture-en-pays-de-la-loire-en-mauvaise-passe",
  },
  {
    legacyId: 303,
    title:
      "Table-ronde De la commande de texte de théâtre jeunesse à l'auteur et conférence de l'auteure Nathalie Papin",
    slug: "table-ronde-de-la-commande-de-texte-de-theatre-jeunesse-a-lauteur-et-conference-de-lauteure-nathalie-papin",
  },
  {
    legacyId: 302,
    title: "Appel à candidatures > résidences-mission",
    slug: "appel-a-candidatures-residences-mission",
  },
  {
    legacyId: 301,
    title:
      "Pour une réelle politique en faveur de l'éducation artistique des jeunes",
    slug: "pour-une-reelle-politique-en-faveur-de-leducation-artistique-des-jeunes",
  },
  {
    legacyId: 300,
    title: "Assemblée Générale du Collectif Jeune Public",
    slug: "assemblee-generale-du-collectif-jeune-public",
  },
  {
    legacyId: 299,
    title:
      'Des écritures théâtrales " pour " la jeunesse ? par Marie Bernanoce',
    slug: "des-ecritures-theatrales-pour-la-jeunesse-par-marie-bernanoce",
  },
  {
    legacyId: 298,
    title:
      'Le Festival "Odyssée en Yvelines", un bastion de la création théâtrale pour la jeunesse',
    slug: "le-festival-odyssee-en-yvelines-un-bastion-de-la-creation-theatrale-pour-la-jeunesse",
  },
  {
    legacyId: 297,
    title:
      "Théâtre jeunesse : attention c'est contagieux, mais c'est aussi fragile...",
    slug: "theatre-jeunesse-attention-cest-contagieux-mais-cest-aussi-fragile",
  },
  {
    legacyId: 296,
    title: "Appel à candidatures",
    slug: "appel-a-candidatures",
  },
  {
    legacyId: 295,
    title: "Fête des écritures théâtrales jeunesse 1er juin 2017",
    slug: "fete-des-ecritures-theatrales-jeunesse-1er-juin-2017",
  },
  {
    legacyId: 294,
    title: "Les lauréats de l'appel à textes Jeune Public des EAT",
    slug: "les-laureats-de-lappel-a-textes-jeune-public-des-eat",
  },
  {
    legacyId: 293,
    title: "Le RDV des Collecteurs",
    slug: "le-rdv-des-collecteurs",
  },
  {
    legacyId: 292,
    title: "Outils numériques, nouvelles écritures, nouvelles dramaturgies ?",
    slug: "outils-numeriques-nouvelles-ecritures-nouvelles-dramaturgies-",
  },
  {
    legacyId: 291,
    title:
      "La mémoire créative de la révolution syrienne, exposition conçue par Sana Yazigi",
    slug: "la-memoire-creative-de-la-revolution-syrienne-exposition-concue-par-sana-yazigi",
  },
  {
    legacyId: 290,
    title:
      "Rapport > Évaluation-valorisation : l'éducation artistique à la loupe ",
    slug: "rapport-evaluation-valorisation-leducation-artistique-a-la-loupe",
  },
  {
    legacyId: 289,
    title: "Politique interministérielle en faveur de l'EAC",
    slug: "politique-interministerielle-en-faveur-de-leac",
  },
  {
    legacyId: 288,
    title: "Les territoires de l'éducation artistique et culturelle",
    slug: "les-territoires-de-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 287,
    title: "Les actes des Journées nationales de l'EAC",
    slug: "les-actes-des-journees-nationales-de-leac",
  },
  {
    legacyId: 286,
    title: "La recette de Sylvain Levey : pour un 1er juin réussi !",
    slug: "la-recette-de-sylvain-levey-pour-un-1er-juin-reussi-",
  },
  {
    legacyId: 285,
    title: "Enseignants de collèges, connaissez-vous le Prix Collidram ?",
    slug: "enseignants-de-colleges-connaissez-vous-le-prix-collidram-",
  },
  {
    legacyId: 284,
    title: "Stelle di carta. Parole in scena",
    slug: "stelle-di-carta-parole-in-scena",
  },
  {
    legacyId: 283,
    title:
      "Rencontres interprofessionnelles : Adolescence, culture, éducation, justice et santé",
    slug: "rencontres-interprofessionnelles-adolescence-culture-education-justice-et-sante",
  },
  {
    legacyId: 282,
    title: "Candidature au Conseil d’Administration de l’ANRAT 2018",
    slug: "candidature-au-conseil-dadministration-de-lanrat-2018",
  },
  {
    legacyId: 280,
    title: "Culture & citoyenneté : quels défis à relever ?",
    slug: "culture-citoyennete-quels-defis-a-relever-",
  },
  {
    legacyId: 279,
    title:
      "Le Défilé des Géants > ateliers gratuits de construction et de manipulation de marionnettes géantes",
    slug: "le-defile-des-geants-ateliers-gratuits-de-construction-et-de-manipulation-de-marionnettes-geantes",
  },
  {
    legacyId: 278,
    title: 'Connaissez-vous la mission "Vivre Ensemble ?"',
    slug: "connaissez-vous-la-mission-vivre-ensemble-",
  },
  {
    legacyId: 277,
    title:
      'Lycéens, devenez organisateur du spectacle "Contagion" et découvrez les métiers du spectacle vivant !',
    slug: "lyceens-devenez-organisateur-du-spectacle-contagion-et-decouvrez-les-metiers-du-spectacle-vivant-",
  },
  {
    legacyId: 276,
    title: "Appel à participation > stage de théâtre",
    slug: "appel-a-participation-stage-de-theatre",
  },
  {
    legacyId: 275,
    title:
      'Journée départementale de l\'éducation artistique et culturelle "Chemins de la Culture" 2017',
    slug: "journee-departementale-de-leducation-artistique-et-culturelle-chemins-de-la-culture-2017",
  },
  {
    legacyId: 274,
    title: "3ème édition du 1er juin des écritures théâtrales jeunesse",
    slug: "3eme-edition-du-1er-juin-des-ecritures-theatrales-jeunesse",
  },
  {
    legacyId: 273,
    title:
      "Appel à chercheurs > ces lieux où l'on pense - scène, musée, bibliothèque",
    slug: "appel-a-chercheurs-ces-lieux-ou-lon-pense-scene-musee-bibliotheque",
  },
  {
    legacyId: 272,
    title: "Les coups de coeur de Pierre-Yves Chapalain",
    slug: "les-coups-de-coeur-de-pierre-yves-chapalain",
  },
  {
    legacyId: 271,
    title: "Les collecteurs, le comité de lecture jeune public",
    slug: "les-collecteurs-le-comite-de-lecture-jeune-public",
  },
  {
    legacyId: 270,
    title: "Théâlab : théâtre et coopération à l'Atelier Canopé 59 - Lille",
    slug: "thealab-theatre-et-cooperation-a-latelier-canope-59-lille",
  },
  {
    legacyId: 269,
    title: "Éloge de l'élan associatif",
    slug: "eloge-de-lelan-associatif",
  },
  {
    legacyId: 268,
    title: "Fonds d'encouragement aux initiatives des amateurs 2017",
    slug: "fonds-dencouragement-aux-initiatives-des-amateurs-2017",
  },
  {
    legacyId: 267,
    title: "Appel à candidatures > résidences artistiques jeune public",
    slug: "appel-a-candidatures-residences-artistiques-jeune-public",
  },
  {
    legacyId: 266,
    title: "Des élèves associés à un travail de création théâtrale",
    slug: "des-eleves-associes-a-un-travail-de-creation-theatrale",
  },
  {
    legacyId: 265,
    title: "Dialogue entre Georges Forestier et Daniel Loayza",
    slug: "dialogue-entre-georges-forestier-et-daniel-loayza",
  },
  {
    legacyId: 264,
    title: "Appel à candidatures - artistes en résidences-missions",
    slug: "appel-a-candidatures-artistes-en-residences-missions",
  },
  {
    legacyId: 263,
    title:
      "Appel à communication : la représentation de l'adolescent.e au théâtre",
    slug: "appel-a-communication-la-representation-de-ladolescente-au-theatre",
  },
  {
    legacyId: 262,
    title: "Art, culture & économie solidaire. Dix récits d'initiatives",
    slug: "art-culture-economie-solidaire-dix-recits-dinitiatives",
  },
  {
    legacyId: 261,
    title:
      "Appel à candidatures aux troupes de théâtre amateur. Participez aux Téméraires 2017 !",
    slug: "appel-a-candidatures-aux-troupes-de-theatre-amateur-participez-aux-temeraires-2017-",
  },
  {
    legacyId: 260,
    title: "Appel à projets > résidences-mission ",
    slug: "appel-a-projets-residences-mission",
  },
  {
    legacyId: 259,
    title: "Une singulière rencontre autour de Jean-Paul Alègre",
    slug: "une-singuliere-rencontre-autour-de-jean-paul-alegre",
  },
  {
    legacyId: 258,
    title: "Y a-t-il trop d'étranger.e.s dans le monde ?",
    slug: "y-a-t-il-trop-detrangeres-dans-le-monde-",
  },
  {
    legacyId: 257,
    title: "Les devises du théâtre",
    slug: "les-devises-du-theatre",
  },
  {
    legacyId: 256,
    title: "C'est pour bientôt. Passage de cap",
    slug: "cest-pour-bientot-passage-de-cap",
  },
  {
    legacyId: 255,
    title: "Festival La Cour aux Ados #2 au Théâtre du Pélican",
    slug: "festival-la-cour-aux-ados-2-au-theatre-du-pelican",
  },
  {
    legacyId: 254,
    title:
      "Appel à projets : initiatives artistiques et culturelles des amateurs",
    slug: "appel-a-projets-initiatives-artistiques-et-culturelles-des-amateurs",
  },
  {
    legacyId: 251,
    title: "Appel à textes jeune public",
    slug: "appel-a-textes-jeune-public",
  },
  {
    legacyId: 250,
    title: '"Une journée  au théâtre" au Centre Les Halles-Le Marais',
    slug: "une-journee-au-theatre-au-centre-les-halles-le-marais",
  },
  {
    legacyId: 248,
    title: "Le partenariat artistes-enseignants : de l'idée à la réalisation",
    slug: "le-partenariat-artistes-enseignants-de-lidee-a-la-realisation",
  },
  {
    legacyId: 245,
    title: "Découverte des arts vivants à l'école, au collège et au lycée",
    slug: "decouverte-des-arts-vivants-a-lecole-au-college-et-au-lycee",
  },
  {
    legacyId: 243,
    title: "Evaluation-Valorisation : l'éducation artistique à la loupe",
    slug: "evaluation-valorisation-leducation-artistique-a-la-loupe",
  },
  {
    legacyId: 241,
    title: "Internet/Medias sociaux : les formats dramatiques en question",
    slug: "internet-medias-sociaux-les-formats-dramatiques-en-question",
  },
  {
    legacyId: 240,
    title: "Salon du livre et de la presse jeunesse",
    slug: "salon-du-livre-et-de-la-presse-jeunesse",
  },
  {
    legacyId: 239,
    title: "C'est pour bientôt - Spécial émergence",
    slug: "cest-pour-bientot-special-emergence",
  },
  {
    legacyId: 238,
    title: "Sur le chemin du théâtre des amateurs...",
    slug: "sur-le-chemin-du-theatre-des-amateurs",
  },
  {
    legacyId: 237,
    title:
      "Création en cours, nouveau dispositif de résidences d'artistes à l'école",
    slug: "creation-en-cours-nouveau-dispositif-de-residences-dartistes-a-lecole",
  },
  {
    legacyId: 235,
    title:
      'Vidéos et ressources autour de la nouvelle création du Théâtre du Soleil "Une Chambre en Inde"',
    slug: "videos-et-ressources-autour-de-la-nouvelle-creation-du-theatre-du-soleil-une-chambre-en-inde",
  },
  {
    legacyId: 234,
    title: "Appel à candidatures : résidences et présences artistiques",
    slug: "appel-a-candidatures-residences-et-presences-artistiques",
  },
  {
    legacyId: 232,
    title:
      "Ecrire le sensible. Pratiques artistiques et scientifiques à l'épreuve du numérique",
    slug: "ecrire-le-sensible-pratiques-artistiques-et-scientifiques-a-lepreuve-du-numerique",
  },
  {
    legacyId: 229,
    title: "Hommage à Dario Fo",
    slug: "hommage-a-dario-fo",
  },
  {
    legacyId: 228,
    title: "La fin de l'option de théâtre ?",
    slug: "la-fin-de-loption-de-theatre-",
  },
  {
    legacyId: 227,
    title: "D'un 11 septembre à l'autre",
    slug: "dun-11-septembre-a-lautre",
  },
  {
    legacyId: 225,
    title: "La création par et avec l'enfance et la jeunesse",
    slug: "la-creation-par-et-avec-lenfance-et-la-jeunesse",
  },
  {
    legacyId: 224,
    title:
      "La liste des textes finalistes du Grand Prix de Littérature dramatique 2016",
    slug: "la-liste-des-textes-finalistes-du-grand-prix-de-litterature-dramatique-2016",
  },
  {
    legacyId: 223,
    title: "La Grande Échelle",
    slug: "la-grande-echelle",
  },
  {
    legacyId: 222,
    title: "L'accès des jeunes aux pratiques artistiques et culturelles",
    slug: "lacces-des-jeunes-aux-pratiques-artistiques-et-culturelles",
  },
  {
    legacyId: 221,
    title: "Peter Brook : autour de l'espace vide",
    slug: "peter-brook-autour-de-lespace-vide",
  },
  {
    legacyId: 220,
    title: "Lancement de l'action nationale d'éducation artistique d'OCCE-THÉÂ",
    slug: "lancement-de-laction-nationale-deducation-artistique-docce-thea",
  },
  {
    legacyId: 219,
    title: "(Ré)écouter > Table-ronde : Arts et Territoires",
    slug: "reecouter-table-ronde-arts-et-territoires",
  },
  {
    legacyId: 218,
    title:
      "(Ré)écouter >Table-ronde :  L'art comme déplacement, réinventer la médiation",
    slug: "reecouter-table-ronde-lart-comme-deplacement-reinventer-la-mediation",
  },
  {
    legacyId: 217,
    title: "Ateliers Théâtre gratuit pour les 14-22 ans",
    slug: "ateliers-theatre-gratuit-pour-les-14-22-ans",
  },
  {
    legacyId: 216,
    title: "Le CRAATHER",
    slug: "le-craather",
  },
  {
    legacyId: 214,
    title: "Le comité de sélection du Prix Collidram pour l'année 2016-2017",
    slug: "le-comite-de-selection-du-prix-collidram-pour-lannee-2016-2017",
  },
  {
    legacyId: 212,
    title: "Écoles et artistes, la transmission",
    slug: "ecoles-et-artistes-la-transmission",
  },
  {
    legacyId: 209,
    title: "Le Festival des Festivals",
    slug: "le-festival-des-festivals",
  },
  {
    legacyId: 208,
    title:
      "Culture comme agora, articulation entre citoyens, territoires et créativité",
    slug: "culture-comme-agora-articulation-entre-citoyens-territoires-et-creativite",
  },
  {
    legacyId: 207,
    title: "La Fabrique de l'éducation artistique et culturelle",
    slug: "la-fabrique-de-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 206,
    title: "Les droits culturels",
    slug: "les-droits-culturels",
  },
  {
    legacyId: 203,
    title: "Insoutenable métaphysique du mal",
    slug: "insoutenable-metaphysique-du-mal",
  },
  {
    legacyId: 170,
    title: "Charte pour l'éducation artistique et culturelle",
    slug: "charte-pour-leducation-artistique-et-culturelle",
  },
  {
    legacyId: 121,
    title: "Comment les arts de la scène ébranlent le monde ? ",
    slug: "comment-les-arts-de-la-scene-ebranlent-le-monde-",
  },
  {
    legacyId: 96,
    title: "L'appel de Saint-Étienne :  vidéos des tables-rondes en ligne",
    slug: "lappel-de-saint-etienne-videos-des-tables-rondes-en-ligne",
  },
  {
    legacyId: 82,
    title: "L'appel de Saint-Étienne  -  30 ans d'Option Théâtre",
    slug: "lappel-de-saint-etienne-30-ans-doption-theatre",
  },
];

const formationsLegacy = [
  {
    legacyId: 133,
    title: "OPÉRATION MOLIÈRE - CYCLES DE FORMATION",
    slug: "operation-moliere-cycles-de-formation",
  },
  {
    legacyId: 132,
    title:
      "Formation : « Molière sur scène, Molière à l’École : regards croisés » - premier temps d'un cycle dans le cadre de l'Opération Molière",
    slug: "formation-moliere-sur-scene-moliere-a-lecole-regards-croises-premier-temps-dun-cycle-dans-le-cadre-de-loperation-moliere",
  },
  {
    legacyId: 131,
    title: 'Formation en ligne : "Le pire n\'est pas toujours sûr !"',
    slug: "formation-en-ligne-le-pire-nest-pas-toujours-sur-",
  },
  {
    legacyId: 130,
    title:
      "FORMATION ANNULÉE : Les écritures théâtrales contemporaines pour la jeunesse #5 Quelles promesses !",
    slug: "formation-annulee-les-ecritures-theatrales-contemporaines-pour-la-jeunesse-5-quelles-promesses-",
  },
  {
    legacyId: 129,
    title:
      " Douzième session du stage “Pratique de la scénographie” au centre National des Arts de la Rue, l’Atelier 231.",
    slug: "douzieme-session-du-stage-pratique-de-la-scenographie-au-centre-national-des-arts-de-la-rue-latelier-231",
  },
  {
    legacyId: 128,
    title: "Programme de formations de l'ANRAT - saison 2019-2020.",
    slug: "programme-de-formations-de-lanrat-saison-2019-2020",
  },
  {
    legacyId: 127,
    title:
      "COULEUR ET ESPACE - FORMATION ANRAT/FAP/BnF, avec le soutien du Théâtre de la Ville.",
    slug: "couleur-et-espace-formation-anrat-fap-bnf-avec-le-soutien-du-theatre-de-la-ville",
  },
  {
    legacyId: 126,
    title:
      "Séminaire de dramaturgie - La Commune - Centre dramatique national d'Aubervilliers",
    slug: "seminaire-de-dramaturgie-la-commune-centre-dramatique-national-daubervilliers",
  },
  {
    legacyId: 92,
    title:
      "ENTRE LE DIRE ET L'ÉCRIRE : LANGUE ÉCRITE, LANGUE ORALE EN MONDE FRANCOPHONE. UNE HISTOIRE DE TRANSMISSION ?",
    slug: "entre-le-dire-et-lecrire-langue-ecrite-langue-orale-en-monde-francophone-une-histoire-de-transmission-",
  },
  {
    legacyId: 91,
    title:
      "Formation - Que dit le théâtre aux enfants et aux adolescents ? OCCE /La Chartreuse ",
    slug: "formation-que-dit-le-theatre-aux-enfants-et-aux-adolescents-occe-la-chartreuse",
  },
  {
    legacyId: 90,
    title: "SÉMINAIRE 2019 DE L'ANRAT À AVIGNON",
    slug: "seminaire-2019-de-lanrat-a-avignon",
  },
  {
    legacyId: 89,
    title:
      'Les écritures théâtrales contemporaines pour la jeunesse - "Quelle histoire !"',
    slug: "les-ecritures-theatrales-contemporaines-pour-la-jeunesse-quelle-histoire-",
  },
  {
    legacyId: 86,
    title: "LA DRAMATURGIE DES ARTIFICES",
    slug: "la-dramaturgie-des-artifices",
  },
  {
    legacyId: 85,
    title: "FORMATIONS DE L'ANRAT - SAISON 2018-2019",
    slug: "formations-de-lanrat-saison-2018-2019",
  },
  {
    legacyId: 84,
    title:
      "Master MEEF Pratiques et ingénierie de la formation, parcours Art'Enact - ESPÉ de l’académie de Créteil-UPEC",
    slug: "master-meef-pratiques-et-ingenierie-de-la-formation-parcours-artenact-espe-de-lacademie-de-creteil-upec",
  },
  {
    legacyId: 83,
    title: "JOUER AVEC LA MUSIQUE",
    slug: "jouer-avec-la-musique",
  },
  {
    legacyId: 82,
    title: "Stage : Encadrer un atelier de pratique théâtrale - 2018",
    slug: "stage-encadrer-un-atelier-de-pratique-theatrale-2018",
  },
  {
    legacyId: 79,
    title: "Séminaire ANRAT à Avignon du 9 au 13 juillet 2018",
    slug: "seminaire-anrat-a-avignon-du-9-au-13-juillet-2018",
  },
  {
    legacyId: 78,
    title:
      "Formation école du spectateur n°2 autour du spectacle Mélancolie(s), de Julie Deliquet-Collectif In Vitro.",
    slug: "formation-ecole-du-spectateur-n2-autour-du-spectaclemelancolies-de-julie-deliquet-collectif-in-vitro",
  },
  {
    legacyId: 77,
    title: "Formation autour des arts du cirque",
    slug: "formation-autour-des-arts-du-cirque",
  },
  {
    legacyId: 76,
    title:
      "Formation à l’analyse chorale autour du spectacle Vies de papier, Cie La Bande Passante",
    slug: "formation-a-lanalyse-chorale-autour-du-spectacle-vies-de-papier-cie-la-bande-passante",
  },
  {
    legacyId: 75,
    title:
      "Formation à l’analyse de spectacle autour de Besti di scena d’Emma Dante",
    slug: "formation-a-lanalyse-de-spectacle-autour-de-besti-di-scena-demma-dante",
  },
  {
    legacyId: 74,
    title: "L'Ecole du spectateur, pour une pluralité des approches.",
    slug: "lecole-du-spectateur-pour-une-pluralite-des-approches",
  },
  {
    legacyId: 73,
    title: "École du spectateur n°1 -  Formation autour des arts du cirque",
    slug: "ecole-du-spectateur-n1-formation-autour-des-arts-du-cirque",
  },
  {
    legacyId: 72,
    title: "Encadrer un atelier de pratique théâtrale",
    slug: "encadrer-un-atelier-de-pratique-theatrale",
  },
  {
    legacyId: 71,
    title: "Offre de formation  2017/2018 de l'Insatiable et Le vent se lève !",
    slug: "offre-de-formation-2017-2018-de-linsatiable-et-le-vent-se-leve-",
  },
  {
    legacyId: 69,
    title: "Découverte de la Manufacture des Oeillets d'Ivry-sur-Seine",
    slug: "decouverte-de-la-manufacture-des-oeillets-divry-sur-seine",
  },
  {
    legacyId: 68,
    title:
      "Le Labo de l'ANRAT : réinvestir et expérimenter des outils d'analyse du spectacle vivant",
    slug: "le-labo-de-lanrat-reinvestir-et-experimenter-des-outils-danalyse-du-spectacle-vivant",
  },
  {
    legacyId: 67,
    title: "Paroles (en)jeux",
    slug: "paroles-enjeux",
  },
  {
    legacyId: 66,
    title: "Les nouveaux parcours",
    slug: "les-nouveaux-parcours",
  },
  {
    legacyId: 65,
    title: "Retrouver l'émerveillement",
    slug: "retrouver-lemerveillement",
  },
  {
    legacyId: 64,
    title: "Formation Transvers'Arts autour de Soudain l'été dernier",
    slug: "formation-transversarts-autour-de-soudain-lete-dernier",
  },
  {
    legacyId: 63,
    title: "Séances Transvers'Arts à la Maison du geste et de l'image",
    slug: "seances-transversarts-a-la-maison-du-geste-et-de-limage",
  },
  {
    legacyId: 62,
    title:
      "Le Centre Pompidou : à la découverte d'un équipement culturel pluridisciplinaire ",
    slug: "le-centre-pompidou-a-la-decouverte-dun-equipement-culturel-pluridisciplinaire",
  },
  {
    legacyId: 61,
    title:
      "Ecole du spectateur Transvers'Arts autour de 20 000 lieues sous les mers #2",
    slug: "ecole-du-spectateur-transversarts-autour-de-20-000-lieues-sous-les-mers-2",
  },
  {
    legacyId: 60,
    title:
      "Ecole du spectateur Transvers'Arts autour de 20 000 lieues sous les mers #1",
    slug: "ecole-du-spectateur-transversarts-autour-de-20-000-lieues-sous-les-mers-1",
  },
  {
    legacyId: 59,
    title:
      "Réinvestir et expérimenter des outils d'analyse autour du spectacle vivant #3",
    slug: "reinvestir-et-experimenter-des-outils-danalyse-autour-du-spectacle-vivant-3",
  },
  {
    legacyId: 58,
    title:
      "Éducation artistique et culturelle : enjeux, pratiques et ressources",
    slug: "education-artistique-et-culturelle-enjeux-pratiques-et-ressources",
  },
  {
    legacyId: 57,
    title: "À la découverte du Théâtre de la Cité Internationale",
    slug: "a-la-decouverte-du-theatre-de-la-cite-internationale",
  },
  {
    legacyId: 56,
    title:
      "Les écritures théâtrales contemporaines pour la jeunesse. Quelle fête !",
    slug: "les-ecritures-theatrales-contemporaines-pour-la-jeunesse-quelle-fete-",
  },
  {
    legacyId: 55,
    title: "Séminaire Anrat à Avignon 2017",
    slug: "seminaire-anrat-a-avignon-2017",
  },
  {
    legacyId: 54,
    title:
      "Réinvestir et expérimenter des outils d'analyse autour du spectacle vivant #2",
    slug: "reinvestir-et-experimenter-des-outils-danalyse-autour-du-spectacle-vivant-2",
  },
  {
    legacyId: 53,
    title: "Programme de formations 2017 de l'ARIA",
    slug: "programme-de-formations-2017-de-laria",
  },
  {
    legacyId: 52,
    title: "Formation autour du Petit-Maître corrigé de Marivaux",
    slug: "formation-autour-du-petit-maitre-corrige-de-marivaux",
  },
  {
    legacyId: 51,
    title: "Module danse autour de Y Olé ! de José Montalvo",
    slug: "module-danse-autour-de-y-ole-de-jose-montalvo",
  },
  {
    legacyId: 50,
    title: "Ecole du spectateur autour d'Aglaé de La Cie Jean-Michel Rabeux",
    slug: "ecole-du-spectateur-autour-daglae-de-la-cie-jean-michel-rabeux",
  },
  {
    legacyId: 48,
    title: "Atelier plurimédia",
    slug: "atelier-plurimedia",
  },
  {
    legacyId: 47,
    title: "Formation autour de l'analyse chorale",
    slug: "formation-autour-de-lanalyse-chorale",
  },
  {
    legacyId: 46,
    title:
      "Réinvestir et expérimenter des outils d'analyse autour du spectacle vivant #1",
    slug: "reinvestir-et-experimenter-des-outils-danalyse-autour-du-spectacle-vivant-1",
  },
  {
    legacyId: 45,
    title: "Autour de l'inter/transdisciplinarité",
    slug: "autour-de-linter-transdisciplinarite",
  },
  {
    legacyId: 44,
    title:
      "Le Centre Pompidou : à la découverte d'un équipement culturel pluridisciplinaire ",
    slug: "le-centre-pompidou-a-la-decouverte-dun-equipement-culturel-pluridisciplinaire",
  },
  {
    legacyId: 41,
    title:
      " (Dé)formation artistique et culturelle #2 > (ré)écouter / (re)voir",
    slug: "deformation-artistique-et-culturelle-2-reecouter-revoir",
  },
  {
    legacyId: 30,
    title: "Retour en images du séminaire Anrat 2016 à Avignon",
    slug: "retour-en-images-du-seminaire-anrat-2016-a-avignon",
  },
  {
    legacyId: 27,
    title:
      "Retour en images : Les écritures théâtrales contemporaines pour la Jeunesse #1",
    slug: "retour-en-images-les-ecritures-theatrales-contemporaines-pour-la-jeunesse-1",
  },
  {
    legacyId: 20,
    title: "(Dé)formation artistique et culturelle #1 > (ré)écouter / (re)voir",
    slug: "deformation-artistique-et-culturelle-1-reecouter-revoir",
  },
];

export { actualitesLegacy, formationsLegacy };
