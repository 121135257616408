import Navigo from "../node_modules/navigo";
import { useFetch } from "./fetch";
import { renderPage } from "./page";
import { renderHome } from "./home";
import { renderList } from "./list";
import { actualitesLegacy, formationsLegacy } from "./legacyRouter";
import { string_to_slug, renderTitle } from "./utils";
import { renderSearch } from "./search";
import { renderMap } from "./map";
import { renderAdhesion } from "./adhesion";
import { renderOtherRessources } from "./otherResources";

function setRoutes() {
  const router = new Navigo("/");

  router.on("/", function () {
    renderHome();
  });

  router.on("/index.html", function () {
    renderHome();
  });

  router.on("list.html", function () {
    console.log("ha yes anrat");
  });

  router.on("/anrat", async function ({ data }) {
    let path = `anrats?title=rubrique`;
    let response = await useFetch(path);
    if (response.length > 0) {
      !response[0].redirection &&
        renderPage("L'ANRAT", response[0].content, ["L'ANRAT"], response[0]);
    } else {
      console.error("404");
    }
  });

  router.on("/anrat/:id", async function ({ data }) {
    function render(response, isSlug) {
      if (response[0].redirection) {
        if (isSlug) {
          renderList(response[0].slug);
        } else {
          renderList(response[0].url);
        }
      } else {
        renderPage(
          response[0].title,
          response[0].content,
          ["L'ANRAT", response[0].title],
          response[0]
        );
      }
    }

    if (data.id === "carte") {
      renderMap();
      renderTitle("Carte du réseau", "Carte du réseau");
    } else {
      let path = `anrats?slug=${data.id}`;
      let response = await useFetch(path);

      if (response.length > 0) {
        render(response, true);
      } else {
        path = `anrats?url=${data.id}`;
        response = await useFetch(path);

        if (response.length > 0) {
          render(response, false);
        } else {
          console.error("404");
        }
      }
    }
  });

  router.on("/formations", async function ({ data }) {
    let path = `formation-pages?title=rubrique`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          "Formations",
          response[0].content,
          ["Formations"],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/formations/:id", async function ({ data }) {
    let path = `formation-pages?url=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      if (response[0].redirection) {
        renderList(response[0].url);
      } else {
        renderPage(
          response[0].title,
          response[0].content,
          ["Formations", response[0].title],
          response[0]
        );
      }
    } else {
      let path = "";
      let title = "";
      if (!data.id.match(/^\d+$/)) {
        if (formationsLegacy.find((e) => e.slug == data.id)) {
          title = formationsLegacy
            .find((e) => e.slug == data.id)
            .title.replace(/(["])/g, "\\$1");
          path = `formations?slug=${string_to_slug(title)}`;
        } else {
          path = `formations?slug=${data.id}`;
        }
      } else {
        title = formationsLegacy
          .find((e) => e.legacyId == data.id)
          .title.replace(/(["])/g, "\\$1");
        path = `formations?slug=${string_to_slug(title)}`;
      }

      let response = await useFetch(path);
      if (response.length > 0) {
        renderPage(
          response[0].title,
          response[0].content,
          ["Formations", response[0].title],
          response[0]
        );
      }
    }
  });

  router.on("/projets", async function ({ data }) {
    let path = `projet-pages?title=rubrique`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage("Projets", response[0].content, ["Projets"], response[0]);
    } else {
      console.error("404");
    }
  });

  router.on("/projets/:id", async function ({ data }) {
    let path = `projet-pages?slug=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          response[0].title,
          response[0].content,
          ["Projets", response[0].title],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/ressources", async function ({ data }) {
    let path = `ressource-pages?title=rubrique`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          "Ressources",
          response[0].content,
          ["Ressources"],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/ressources/:id", async function ({ data }) {
    function render(response, isSlug) {
      if (response[0].redirection) {
        if (isSlug) {
          renderList(response[0].slug);
        } else {
          renderList(response[0].url);
        }
      } else {
        renderPage(
          response[0].title,
          response[0].content,
          ["Ressources", response[0].title],
          response[0]
        );
      }
    }

    if (data.id === "autres") {
      renderOtherRessources();
    } else {
      let path = `ressource-pages?slug=${data.id}`;
      let response = await useFetch(path);

      if (response.length > 0) {
        render(response, true);
      } else {
        path = `ressource-pages?url=${data.id}`;
        response = await useFetch(path);

        if (response.length > 0) {
          render(response, false);
        } else {
          console.error("404");
        }
      }
    }
  });

  router.on("/ressources/publications/:id", async function ({ data }) {
    let path = `ressource-publications?slug=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          response[0].title,
          response[0].content,
          ["Publications", response[0].title],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/ressources/piece-demontees/:id", async function ({ data }) {
    let path = `ressources-pieces-de-montees?slug=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          response[0].title,
          response[0].content,
          ["Pièces (dé)montées - Nouvelle formule", response[0].title],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/etudes", async function ({ data }) {
    let path = `etude-pages?title=rubrique`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          "Études et recherches",
          response[0].content,
          ["Études et recherches"],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/etudes/:id", async function ({ data }) {
    let path = `etude-pages?slug=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          response[0].title,
          response[0].content,
          ["Études et recherches", response[0].title],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/paroles", async function ({ data }) {
    let path = `paroles-pages?title=rubrique`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage("Paroles", response[0].content, ["Paroles"], response[0]);
    } else {
      console.error("404");
    }
  });

  router.on("/paroles/:id", async function ({ data }) {
    let path = `paroles-pages?slug=${data.id}`;
    let response = await useFetch(path);

    if (response.length > 0) {
      !response[0].redirection &&
        renderPage(
          response[0].title,
          response[0].content,
          ["Paroles", response[0].title],
          response[0]
        );
    } else {
      console.error("404");
    }
  });

  router.on("/actualites", async function ({ data }) {
    renderList("actualites");
  });

  router.on("/actualites/:id", async function ({ data }) {
    let path = "";
    let title = "";
    if (!data.id.match(/^\d+$/)) {
      if (actualitesLegacy.find((e) => e.slug == data.id)) {
        title = actualitesLegacy
          .find((e) => e.slug == data.id)
          .title.replace(/(["])/g, "\\$1");
        path = `actualites?slug=${string_to_slug(title)}`;
      } else {
        path = `actualites?slug=${data.id}`;
      }
    } else {
      title = actualitesLegacy
        .find((e) => e.legacyId == data.id)
        .title.replace(/(["])/g, "\\$1");
      path = `actualites?slug=${string_to_slug(title)}`;
    }

    let response = await useFetch(path);
    if (response.length > 0) {
      renderPage(
        response[0].title,
        response[0].content,
        ["Actualités", response[0].title],
        response[0]
      );
    }
  });

  router.on("/search/:id", async function ({ data }) {
    renderSearch(data.id);
    renderTitle("Recherche", "Recherche");
  });

  router.on("/adherer", async function ({ data }) {
    renderAdhesion();
    renderTitle("Adhérer à l'ANRAT", "Adhérer à l'ANRAT");
  });

  router.on("/succes", async function ({ data }) {
    renderPage(
      "Merci d'avoir adhéré à l'ANRAT !",
      "<a href='/index.html' class='out-btn-black' style='margin:3rem auto;'>Retour à l'accueil</a>",
      ["L'ANRAT"]
    );
  });

  router.on("/cheque/:id", async function ({ data }) {
    let tarif = "";
    switch (data.id) {
      case "individuel1":
        tarif = "30";
        break;
      case "individuel2":
        tarif = "50";
        break;
      case "individuel3":
        tarif = "70";
        break;
      case "tarifA1":
        tarif = "70";
        break;
      case "tarifA2":
        tarif = "100";
        break;
      case "tarifA3":
        tarif = "150";
        break;
      case "tarifB1":
        tarif = "150";
        break;
      case "tarifB2":
        tarif = "180";
        break;
      case "tarifB3":
        tarif = "230";
        break;
      case "tarifC1":
        tarif = "250";
        break;
      case "tarifC2":
        tarif = "280";
        break;
      case "tarifC3":
        tarif = "330";
        break;
    }
    renderPage(
      "Merci, vos informations nous ont bien été transmises.",
      `<p>Afin de <span class='bold'>finaliser votre adhésion</span>, merci de nous adresser un chèque de <span class='bold'>${tarif}€</span> à l'ordre : <span class='bold'>ANRAT</span> 
      et à l'adresse suivante : <span class='bold'>ANRAT c/o F93 70, rue Douy Delcupe 93100 Montreuil</span></p>
<br><a href='/index.html' class='out-btn-black' style='margin:3rem auto;'>Retour à l'accueil</a>`,
      ["L'ANRAT"]
    );
  });

  router.on("/rib/:id", async function ({ data }) {
    console.log("hey");
    let tarif = "";
    switch (data.id) {
      case "individuel1":
        tarif = "30";
        break;
      case "individuel2":
        tarif = "50";
        break;
      case "individuel3":
        tarif = "70";
        break;
      case "tarifA1":
        tarif = "70";
        break;
      case "tarifA2":
        tarif = "100";
        break;
      case "tarifA3":
        tarif = "150";
        break;
      case "tarifB1":
        tarif = "150";
        break;
      case "tarifB2":
        tarif = "180";
        break;
      case "tarifB3":
        tarif = "230";
        break;
      case "tarifC1":
        tarif = "250";
        break;
      case "tarifC2":
        tarif = "280";
        break;
      case "tarifC3":
        tarif = "330";
        break;
    }
    renderPage(
      "Merci, vos informations nous ont bien été transmises.",
      `<p>Afin de <span class='bold'>finaliser votre adhésion</span>, merci de nous adresser un virement bancaire de <span class='bold'>${tarif}€</span> : <br><br> IBAN : <span class='bold'>FR76 4255 9100 0008 0140 4693 978</span>  <br> BIC : <span class='bold'>C C O P F R P P X X X</span> </p>
<br><a href='/index.html' class='out-btn-black' style='margin:3rem auto;'>Retour à l'accueil</a>`,
      ["L'ANRAT"]
    );
  });

  router.notFound(() => {
    console.log("ohoho 404");
    renderPage(
      "Page introuvable",
      `<a href='/index.html' class='out-btn-black' style='margin:3rem auto;'>Retour à l'accueil</a>`,
      [""]
    );
  });

  router.resolve();
}

export { setRoutes };
