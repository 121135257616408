/* import "../css/index.scss"; */
import "regenerator-runtime/runtime";
import { setRoutes } from "./routes";
import { renderMenu } from "./menu";
import { renderFooter } from "./footer";

setRoutes();
renderMenu();
renderFooter();

const $searchForm = document.getElementById("search-form");
const $searchInput = document.getElementById("search");

$searchForm.addEventListener("submit", (ev) => {
  ev.preventDefault();
  if ($searchInput.value) {
    window.location = "/search/" + $searchInput.value;
  }
});

const $rgpd = document.getElementsByClassName("rgpd")[0];
const $rgpdYes = document.getElementById("rgpd-yes");
const $rgpdNo = document.getElementById("rgpd-no");

if (!localStorage.getItem("anrat-rgpd")) {
  $rgpd.classList.remove("rgpd-no");
}

$rgpdYes.addEventListener("click", (ev) => {
  ev.preventDefault();
  $rgpd.classList.add("rgpd-hide");
  localStorage.setItem("anrat-rgpd", "true");
});

$rgpdNo.addEventListener("click", (ev) => {
  ev.preventDefault();
  $rgpd.classList.add("rgpd-hide");
  localStorage.setItem("anrat-rgpd", "false");
});
