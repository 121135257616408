import { useFetch } from "./fetch";
import {
  capitalizeFirstLetter,
  cloudinarySetup,
  string_to_slug,
} from "./utils";

async function renderBlocs() {
  let response = await useFetch("accueil");
  let response2 = await useFetch("actualites?_sort=created_at:DESC");

  let bloc2 = response.sideBlocs;
  let bloc2HTML = "";

  if (bloc2.title) {
    bloc2HTML += `<h2>${bloc2.title}</h2>`;
  }
  if (bloc2.subtitle) {
    bloc2HTML += `<p>${bloc2.subtitle}</p>`;
  }
  if (bloc2.image) {
    bloc2HTML += `<img src="${bloc2.image.url}" alt="">`;
  }
  if (bloc2.link1name && bloc2.link1url) {
    bloc2HTML += `<a href="${bloc2.link1url}" class="out-btn">${bloc2.link1name}</a>`;
  }
  if (bloc2.link2name && bloc2.link2url) {
    bloc2HTML += `<a href="${bloc2.link2url}" class="out-btn">${bloc2.link2name}</a>`;
  }
  if (bloc2.link3name && bloc2.link3url) {
    bloc2HTML += `<a href="${bloc2.link3url}" class="out-btn">${bloc2.link3name}</a>`;
  }

  let bloc3 = response.sideBlocs2;
  let bloc3HTML = "";

  if (bloc3.title) {
    bloc3HTML += `<h2>${bloc3.title}</h2>`;
  }
  if (bloc3.subtitle) {
    bloc3HTML += `<p>${bloc3.subtitle}</p>`;
  }
  if (bloc3.image) {
    bloc3HTML += `<img src="${bloc3.image.url}" alt="">`;
  }
  if (bloc3.link1name && bloc3.link1url) {
    bloc3HTML += `<a href="${bloc3.link1url}" class="out-btn">${bloc3.link1name}</a>`;
  }
  if (bloc3.link2name && bloc3.link2url) {
    bloc3HTML += `<a href="${bloc3.link2url}" class="out-btn">${bloc3.link2name}</a>`;
  }
  if (bloc3.link3name && bloc3.link3url) {
    bloc3HTML += `<a href="${bloc3.link3url}" class="out-btn">${bloc3.link3name}</a>`;
  }

  let actualites = [response2[0], response2[1], response2[2]];

  let blocsHTML = `<div class="bloc-1 bloc">
          <h2>Les dernières actualités</h2>
          <ul>
            <li>
              <a href="/actualites/${string_to_slug(actualites[0].title)}">${
    actualites[0].title
  }</a>
            </li>
            <li>
              <a href="/actualites/${string_to_slug(actualites[1].title)}">${
    actualites[1].title
  }</a>
              </li>
            <li>
              <a href="/actualites/${string_to_slug(actualites[2].title)}">${
    actualites[2].title
  }</a>
            </li>
          </ul>
          <a href="/actualites" class="out-btn">Voir toutes les actualités</a>
        </div>
        <div class="bloc-2 bloc">
        ${bloc2HTML}
        </div>
        <div class="bloc-3 bloc">
          ${bloc3HTML}
        </div>`;

  const $header = document.querySelector("header");
  if (
    response.backgrounds[
      capitalizeFirstLetter(window.location.href.split("/")[3])
    ]
  ) {
    $header.style.backgroundImage = `url("${cloudinarySetup(
      response.backgrounds[
        capitalizeFirstLetter(window.location.href.split("/")[3])
      ].formats.large.url
    )}")`;
  } else {
    $header.style.backgroundImage = `url("https://res.cloudinary.com/anrat/image/upload/f_auto/large_reseau_9516e90a5c.jpg")`;
  }
  let screenWidth = window.innerWidth > 0 ? window.innerWidth : screen.width;
  if (screenWidth < 600) {
    $header.style.backgroundImage = "none";
  }

  return blocsHTML;
}

export { renderBlocs };
