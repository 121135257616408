import { useFetch } from "./fetch";
import { compare, compareOrder, addItalic } from "./utils";
import { subscribe } from "./newsletter";

function setResponsiveMenu() {
  const $iconMenu = document.getElementById("menu-icon");
  const $closeMenu = document.getElementById("menu-close");
  const $nav = document.getElementById("nav-responsive");

  const $anratMenu = {
    category: document.getElementById("menu-res-anrats-category"),
    pages: document.getElementById("menu-res-anrats"),
  };
  const $formationsMenu = {
    category: document.getElementById("menu-res-formations-category"),
    pages: document.getElementById("menu-res-formations"),
  };
  const $projetsMenu = {
    category: document.getElementById("menu-res-projets-category"),
    pages: document.getElementById("menu-res-projets"),
  };
  const $ressourcesMenu = {
    category: document.getElementById("menu-res-ressources-category"),
    pages: document.getElementById("menu-res-ressources"),
  };
  const $etudesMenu = {
    category: document.getElementById("menu-res-etudes-category"),
    pages: document.getElementById("menu-res-etudes"),
  };
  const $parolesMenu = {
    category: document.getElementById("menu-res-paroles-category"),
    pages: document.getElementById("menu-res-paroles"),
  };

  $iconMenu.addEventListener("click", (ev) => {
    ev.preventDefault();
    $nav.style.display = "flex";
  });

  $closeMenu.addEventListener("click", (ev) => {
    ev.preventDefault();
    $nav.style.display = "none";
  });

  function addEvent(node) {
    node.addEventListener("click", (ev) => {
      ev.preventDefault();
      node.childNodes[1].innerText = "expand_less";
      node.nextElementSibling.style.display = "block";

      node.addEventListener("click", (ev) => {
        ev.preventDefault();
        node.childNodes[1].innerText = "expand_more";
        node.nextElementSibling.style.display = "none";
        addEvent(node);
      });
    });
  }

  addEvent($anratMenu.category);
  addEvent($formationsMenu.category);
  addEvent($projetsMenu.category);
  addEvent($ressourcesMenu.category);
  addEvent($etudesMenu.category);
  addEvent($parolesMenu.category);
}

function headerCta() {
  const $ctaNewsletter = document.getElementById("ctaNewsletter");
  $ctaNewsletter.addEventListener("click", (ev) => {
    ev.preventDefault();
    $ctaNewsletter.outerHTML = `<form id="newsletterHeader">
    <input
      type="email"
      name="email"
      id="newsletterInputHeader"
      placeholder="Votre adresse courriel"
    />
    <button type="submit">S'inscrire</button>
  </form>`;
    const $newsletterHeader = document.getElementById("newsletterHeader");
    const $newsletterInputHeader = document.getElementById(
      "newsletterInputHeader"
    );

    $newsletterHeader.addEventListener("submit", (ev) => {
      ev.preventDefault();
      subscribe($newsletterInputHeader.value);
    });
  });
}

async function renderMenu() {
  headerCta();

  const categories = [
    {
      name: "anrat",
      dom: document.getElementById("menu-anrats"),
      responsive: document.getElementById("menu-res-anrats"),
      endpoint: "anrats",
    },
    {
      name: "formations",
      dom: document.getElementById("menu-formations"),
      responsive: document.getElementById("menu-res-formations"),
      endpoint: "formation-pages",
    },
    {
      name: "projets",
      dom: document.getElementById("menu-projets"),
      responsive: document.getElementById("menu-res-projets"),
      endpoint: "projet-pages",
    },
    {
      name: "ressources",
      dom: document.getElementById("menu-ressources"),
      responsive: document.getElementById("menu-res-ressources"),
      endpoint: "ressource-pages",
    },
    {
      name: "etudes",
      dom: document.getElementById("menu-etudes"),
      responsive: document.getElementById("menu-res-etudes"),
      endpoint: "etude-pages",
    },
    {
      name: "paroles",
      dom: document.getElementById("menu-paroles"),
      responsive: document.getElementById("menu-res-paroles"),
      endpoint: "paroles-pages",
    },
  ];

  await categories.map(async (category) => {
    category.pages = await useFetch(category.endpoint);

    /* sort by order */
    category.pages.sort(compareOrder);

    category.dom.innerHTML = "";
    category.responsive.innerHTML = "";

    category.dom.previousElementSibling.setAttribute(
      "href",
      "/" + category.name
    );

    category.pages.map((page, i) => {
      if (page.title !== "rubrique") {
        if (page.redirection) {
          category.dom.innerHTML += `<li><a href="/${category.name}/${
            page.url
          }" data-navigo>${addItalic(page.title)}</a></li>`;
          category.responsive.innerHTML += `<li><a href="/${category.name}/${
            page.url
          }" data-navigo>${addItalic(page.title)}</a></li>`;
        } else {
          category.dom.innerHTML += `<li><a href="/${category.name}/${
            page.slug
          }" data-navigo>${addItalic(page.title)}</a></li>`;
          category.responsive.innerHTML += `<li><a href="/${category.name}/${
            page.slug
          }" data-navigo>${addItalic(page.title)}</a></li>`;
        }
      }
    });
  });

  setTimeout(setResponsiveMenu(), 1000);
}

export { renderMenu };
