import {
  string_to_slug,
  cloudinarySetup,
  renderShowMore,
  customHTML,
  mutation,
  stripHtml,
  renderTitle,
  addItalic,
} from "./utils";
import { renderBlocs } from "./blocs";
import moment from "../node_modules/moment-with-locales-es6/";

async function renderPage(title, content, ariane, response = {}) {
  renderTitle(title, stripHtml(content).substring(0, 150));

  const $main = document.getElementById("main");
  $main.classList.remove("grid");
  $main.innerHTML = "";

  mutation($main, customHTML);

  content = renderShowMore(content);

  console.log("title", title);
  console.log("content", content);
  console.log("ariane", ariane);
  console.log("response", response);

  if (ariane[0] === "Formations") {
    let startDate = new Date();
    let endDate = new Date();
    let dateHTML = "";
    let placeHTML = "";
    let teacherHTML = "";

    if (response.startDate && response.endDate) {
      startDate = moment(response.startDate);
      endDate = moment(response.endDate);
      dateHTML = `<p><strong>Date</strong> : du ${startDate
        .locale("fr")
        .format("LLL")} au ${endDate.locale("fr").format("LLL")}</p>`;
    } else {
      startDate = "";
      endDate = "";
      dateHTML = "";
    }

    if (response.place) {
      placeHTML = `<p><strong>Lieu</strong> : ${
        response.place ? response.place : ""
      }</p>`;
    }

    if (response.place) {
      teacherHTML = `<p><strong>Intervenant.e.s</strong> : ${
        response.teacher ? response.teacher : ""
      }</p>`;
    }

    let infos = `<div class="infos">
                ${placeHTML}
                ${dateHTML}
                ${teacherHTML}
                </div>`;

    let detailedFileHTML = "";
    let registerFormHTML = "";
    let emailHTML = "";

    if (response.detailedFile || response.detailedFileUrl) {
      detailedFileHTML = `<a href="${
        response.detailedFile?.url || response.detailedFileUrl
      }" class="cta" target="_blank">
    <span class="material-icons-round">
visibility
</span>
 Voir la fiche détaillée</a>`;
    }

    if (response.registerForm || response.registerFormUrl) {
      registerFormHTML = `<a href="${
        response.registerForm?.url || response.registerFormUrl
      }" class="cta" target="_blank">
    <span class="material-icons-round">
file_download
</span>
    Téléchargez le bulletin d'inscription</a>`;
    }

    if (response.email) {
      emailHTML = `<a href="mailto:${response.email}" class="cta" target="_blank">
    <span class="material-icons-round">
email
</span>
    Envoyez le bulletin d'inscription</a>`;
    }

    let ctas = `<div class="ctas">
    ${detailedFileHTML}
    ${registerFormHTML}
    ${emailHTML}
    </div>`;
    content = infos + content + ctas;
  }

  if (ariane[0] === "Publications") {
    let infos = "";
    let ctas = "";
    infos = `<div class="infos">
    <p><strong>Catégorie</strong> : ${response.category}</p>
    </div>`;

    if (response.ctaButton === "soldOut") {
      ctas = `<div class="purchase">
      <img src="${
        (response.image?.formats?.thumbnail?.url &&
          cloudinarySetup(response.image?.formats?.thumbnail?.url)) ||
        (response.imageUrl && cloudinarySetup(response.imageUrl)) ||
        "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
      }">
      </div>`;
    }
    if (response.ctaButton === "purchaseOrder") {
      console.log("no soldOut");
      ctas = `<div class="purchase">
    <img src="${
      (response.image?.formats?.thumbnail?.url &&
        cloudinarySetup(response.image?.formats?.thumbnail?.url)) ||
      (response.imageUrl && cloudinarySetup(response.imageUrl)) ||
      "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
    }">
    <div class="purchase__text">
    <h3>Téléchargez, imprimez et renvoyez le bon de commande</h3>
    <div class="ctas">
    <a href="${
      response.purchaseOrder?.url || response.purchaseOrderUrl
    }" class="cta" target="_blank"><span class="material-icons-round">
file_download
</span>Téléchargez le bon de commande</a>
    </div>
    </div>
    </div>`;
    }
    if (response.ctaButton === "downloadPDF") {
      ctas = `<div class="purchase">
      <a href="${
        response.purchaseOrder?.url || response.purchaseOrderUrl
      }" class="img-link"><img src="${
        (response.image?.formats?.thumbnail?.url &&
          cloudinarySetup(response.image?.formats?.thumbnail?.url)) ||
        (response.imageUrl && cloudinarySetup(response.imageUrl)) ||
        "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
      }"></a>
    <div class="purchase__text">
    <div class="ctas">
    <a href="${
      response.pdf?.url ||
      response.pdf ||
      response.purchaseOrder?.url ||
      response.purchaseOrderUrl ||
      "#"
    }" class="cta" target="_blank"><span class="material-icons-round">
file_download
</span>Téléchargez le PDF</a>
    </div>
    </div>
    </div>`;
    }
    content = infos + content + ctas;
  }

  if (ariane[0] === "Pièces (dé)montées - Nouvelle formule") {
    let ctas = "";

    ctas = `<div class="purchase">
      <a href="${
        response.purchaseOrder?.url || response.purchaseOrderUrl
      }" class="img-link"><img src="${
      (response.image?.formats?.thumbnail?.url &&
        cloudinarySetup(response.image?.formats?.thumbnail?.url)) ||
      (response.imageUrl && cloudinarySetup(response.imageUrl)) ||
      "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
    }"></a>
    <div class="purchase__text">
    <div class="ctas">
    <a href="${
      response.pdfUrl || "#"
    }" class="cta" target="_blank"><span class="material-icons-round">
file_download
</span>Téléchargez le PDF</a>
    </div>
    </div>
    </div>`;
    content = content + ctas;
  }

  let blocsHTML = await renderBlocs();

  $main.innerHTML = `<section>
        <div class="ariane">
         <a href="/index.html">Accueil</a> / <a href="/${
           ariane[0] === "Publications"
             ? "ressources/publications"
             : window.location.href.split("/")[3]
         }">${ariane[0]}</a> / ${ariane[1] ? addItalic(ariane[1]) : ""}
        </div>
        <h1>${addItalic(title)}</h1>
        <div class="body">
          ${content}
        </div>
      </section>
      <div class="blocs">
        ${blocsHTML}
      </div>`;
}

export { renderPage };
