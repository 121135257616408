async function subscribe(email) {
  const response = await fetch("https://anrat-back.herokuapp.com/subscribe", {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ "email": email }),
  });

  const $alert = document.querySelector(".alert");

  if (response.status === 200) {
    $alert.classList.remove("error")
    $alert.innerHTML = `<p>Votre inscription à la newsletter a bien été validée</p>`
    $alert.style.display = "flex"
    setTimeout(() => {$alert.style.display = "none"},6000)
  } else {
    $alert.classList.add("error")
    $alert.innerHTML = `<p>Votre inscription a échoué. Êtes-vous sûr d'avoir rentré une adresse e-mail correcte ?</p>`
    $alert.style.display = "flex"
    setTimeout(() => {$alert.style.display = "none"},6000)
  }
}

export { subscribe };
