function compare(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

function compareOrder(a, b) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}

function compareDate(a, b) {
  const aDate = new Date(a.creationDate || a.published_at);
  const bDate = new Date(b.creationDate || b.published_at);

  if (aDate < bDate) {
    return -1;
  }
  if (aDate > bDate) {
    return 1;
  }
  return 0;
}

function string_to_slug(str) {
  if (str) {
    str = str.replace(/^\s+|\s+$/g, ""); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
      .replace(/\s+/g, "-") // collapse whitespace and replace by -
      .replace(/-+/g, "-"); // collapse dashes

    return str;
  } else {
    console.error("str is empty or undefined");
  }
}

function stripHtml(html = "") {
  html = stripTags(html);
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function stripTags(str = "") {
  if (str) {
    str = str.replace("/[exp]/g", "");
    str = str.replace("/[/exp]/g", "");
    str = str.replace("/[dropdown]/g", "");
    str = str.replace("/[/dropdown]/g", "");
    str = str.replace("/[diapo]/g", "");
    str = str.replace("/[/diapo]/g", "");
    return str;
  }
}

function cloudinarySetup(url, square = false) {
  let urlArr = url.split("/");
  square ? (urlArr[6] = `f_auto,g_center,h_280,w_280`) : (urlArr[6] = `f_auto`);
  return urlArr.join("/");

  //res.cloudinary.com/dgu2by95h/image/upload/v1607620856/u30dtatlv2nop1yzy8j2.gif
  //res.cloudinary.com/dgu2by95h/image/upload/f_auto,g_center,h_280,w_280/u30dtatlv2nop1yzy8j2.gif
}

function showMoreEvent() {
  const $showMores = document.querySelectorAll(".show-more");

  /* big titles */
  /* const $titles = document.querySelectorAll(".body h2");
  var arr = [].slice.call($titles);
  arr.map((elem) => {
    elem.classList.add("anrat-titles");
  }); */

  $showMores.forEach((el) => {
    el.addEventListener("click", (ev) => {
      ev.preventDefault();
      var arr = [].slice.call(el.parentElement.children);
      arr.map((elem) => {
        elem.classList.remove("p-hide");
      });
      el.outerHTML = "";
    });
  });
}

function renderShowMore(content) {
  const $main = document.getElementById("main");
  const element = document.createElement("div");
  element.innerHTML = content;

  let newHTML = "";
  let afterDrop = false;

  for (let i = 0; i < element.children.length; i++) {
    if (element.children[i].innerHTML.includes("[dropdown]")) {
      newHTML += `<div class="drop">`;
      afterDrop = true;
    } else if (element.children[i].innerHTML.includes("[/dropdown]")) {
      newHTML += `</div>`;
    } else {
      if (afterDrop) {
        newHTML += `<p class="p-first">${element.children[i].innerHTML}</p>
        <p class="show-more"><span class="material-icons-round">
expand_more
</span>
 En savoir plus</p>`;
      } else {
        element.children[i].classList.add("p-hide");

        newHTML += element.children[i].outerHTML;
      }
      afterDrop = false;
    }
  }

  // setTimeout(showMoreEvent, 3000);
  mutation($main, showMoreEvent);
  return newHTML;
}

const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join("");

function capitalizeFirstName(str) {
  let capitalizeNext = true;
  str = str.split("");
  for (let i in str) {
    if (capitalizeNext) {
      str[i] = str[i].toUpperCase();
    } else {
      str[i] = str[i].toLowerCase();
    }
    if (str[i] === "-" || str[i] === " ") {
      capitalizeNext = true;
    } else {
      capitalizeNext = false;
    }
  }
  return str.join("");
}

function deleteDuplicate(arr) {
  let unique = [];
  arr.map((el) => {
    if (unique.find((e) => e.id === el.id) === undefined) {
      unique.push(el);
    }
  });
  return unique;
}

function isKeywordInString(keyword, str) {
  const regex = new RegExp(keyword + "*");
  return regex.test(str);
}

function string_to_downcase(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    /* .replace(/\s+/g, "-") */ // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

function find_duplicate_in_array(array, times) {
  const count = {};
  const result = [];

  array.forEach((item) => {
    if (count[item]) {
      count[item] += 1;
      return;
    }
    count[item] = 1;
  });

  for (let prop in count) {
    if (count[prop] >= times) {
      result.push(prop);
    }
  }
  return result;
}

function findDuplicates(arr, times) {
  let ids = [];
  let result = [];

  arr.map((el) => {
    ids.push(el.id);
  });

  find_duplicate_in_array(ids, times).map((el) => {
    result.push(arr.find((e) => e.id == el));
  });

  return result;
}

function customHTML() {
  const $body = document.querySelector(".body");

  /* YouTube */
  const $media = document.querySelectorAll(".media");
  $media.forEach((media) => {
    if (media.firstElementChild.attributes.url) {
      let youtubeId =
        media.firstElementChild.attributes.url.value.split("/")[3];
      media.innerHTML = `<iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/${youtubeId}"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>`;
    }
  });

  /* Outlink */
  const $links = document.querySelectorAll(".body a");
  $links.forEach((link) => {
    if (!link.hasAttribute("target") && link.host !== window.location.host) {
      link.setAttribute("target", "_blank");
    }
  });

  /* Exposant */
  for (let i = 0; i < $body.children.length; i++) {
    if ($body.children[i].innerHTML.includes("[exp]")) {
      $body.children[i].innerHTML = $body.children[i].innerHTML.replace(
        "[exp]",
        "<sup>"
      );
    }
    if ($body.children[i].innerHTML.includes("[/exp]")) {
      $body.children[i].innerHTML = $body.children[i].innerHTML.replace(
        "[/exp]",
        "</sup>"
      );
    }
  }

  /* Diapo */
  let diapoHTML = "";
  let inDiapo = false;
  let firstImg = true;
  let $diapo;

  for (let i = 0; i < $body.children.length; i++) {
    if ($body.children[i].innerHTML === "[diapo]") {
      $body.children[i].outerHTML = `<div class="diapo" id="diapo"></div>`;
      $diapo = $body.children[i];
      inDiapo = true;
    } else if ($body.children[i].innerHTML === "[/diapo]") {
      inDiapo = false;
      $body.children[i].outerHTML = "<span></span>";
    } else if (inDiapo) {
      if (firstImg) {
        firstImg = false;
        $body.children[i].classList.add("diapo__show");
        $body.children[i].classList.add("diapo__img");
      } else {
        $body.children[i].classList.add("diapo__hide");
        $body.children[i].classList.add("diapo__img");
      }
      diapoHTML += $body.children[i].outerHTML;
      $body.children[i].outerHTML = "<span></span>";
    }
  }

  if ($diapo) {
    diapoHTML =
      `<img src="https://res.cloudinary.com/anrat/image/upload/f_auto/previous_l2hwtt.png" class="diapo__icon" id="diapo-previous">` +
      diapoHTML +
      `<img src="https://res.cloudinary.com/anrat/image/upload/f_auto/next_vfexqr.png" class="diapo__icon" id="diapo-next">`;
    $diapo.innerHTML = diapoHTML;

    diapo();
  }
}

function diapo() {
  const $previous = document.getElementById("diapo-previous");
  const $next = document.getElementById("diapo-next");

  $next.addEventListener("click", (ev) => {
    ev.preventDefault();
    const $allImg = document.querySelectorAll(".diapo__img");
    let one = true;

    for (let i = 0; i < $allImg.length; i++) {
      if (one) {
        if ($allImg[i].classList.contains("diapo__show")) {
          if (i + 1 !== $allImg.length) {
            $allImg[i].classList.replace("diapo__show", "diapo__hide");
            $allImg[i + 1].classList.replace("diapo__hide", "diapo__show");
          } else {
            $allImg[i].classList.replace("diapo__show", "diapo__hide");
            $allImg[0].classList.replace("diapo__hide", "diapo__show");
          }
          one = false;
        }
      }
    }
  });

  $previous.addEventListener("click", (ev) => {
    ev.preventDefault();
    const $allImg = document.querySelectorAll(".diapo__img");
    let one = true;

    for (let i = 0; i < $allImg.length; i++) {
      if (one) {
        if ($allImg[i].classList.contains("diapo__show")) {
          if (i !== 0) {
            $allImg[i].classList.replace("diapo__show", "diapo__hide");
            $allImg[i - 1].classList.replace("diapo__hide", "diapo__show");
          } else {
            $allImg[i].classList.replace("diapo__show", "diapo__hide");
            $allImg[$allImg.length - 1].classList.replace(
              "diapo__hide",
              "diapo__show"
            );
          }
          one = false;
        }
      }
    }
  });
}

function mutation($node, callback) {
  const config = { attributes: true, childList: true, subtree: true };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe($node, config);

  setTimeout(() => {
    observer.disconnect();
  }, 5000);
}

function renderTitle(
  title = "Association Nationale de Recherche et d'Action Théâtrale",
  description = "Association Nationale de Recherche et d'Action Théâtrale"
) {
  document.title = deleteStars(title) + " - ANRAT";
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", description);
}

function addItalic(str = "") {
  if (str) {
    str = str.replace("**", `<span class="italic">`);
    str = str.replace("**", "</span>");
    return str;
  }
}

function deleteStars(str = "") {
  return str.replace("/**/g", "");
}

export {
  compare,
  compareDate,
  compareOrder,
  string_to_slug,
  stripHtml,
  cloudinarySetup,
  renderShowMore,
  capitalizeFirstLetter,
  isKeywordInString,
  deleteDuplicate,
  findDuplicates,
  string_to_downcase,
  customHTML,
  mutation,
  diapo,
  renderTitle,
  capitalizeFirstName,
  addItalic,
};
