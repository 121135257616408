import "../node_modules/leaflet/dist/leaflet";
import { icon, Marker } from "leaflet";
import "../node_modules/leaflet.markercluster/dist/leaflet.markercluster-src";
import "../node_modules/leaflet/dist/leaflet.css";
import "../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
import { useFetch, usePost } from "./fetch";
import { renderBlocs } from "./blocs";
import { string_to_slug, mutation, capitalizeFirstName } from "./utils";

let personalAdherents = [];

/* async function postArchive(adherents) {
  console.log(`adherents`, adherents);
  adherents.map(async (adherent, id) => {
    let response = await usePost("carte-adherents-2-s", adherent);
  });
} */

function setMap() {
  // change icon url
  const iconRetinaUrl =
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png";
  const iconUrl = "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png";
  const shadowUrl =
    "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png";
  const iconDefault = icon({
    iconRetinaUrl,
    iconUrl,
    shadowUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
  Marker.prototype.options.icon = iconDefault;

  var mymap = L.map("mapid").setView([46.8, 2.65], 6);

  L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
    {
      attribution:
        'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: "mapbox/streets-v11",
      tileSize: 512,
      zoomOffset: -1,
      accessToken:
        "pk.eyJ1IjoicGFibG9za3oiLCJhIjoiY2txbDR0YnlnM3d4YTMybXZ3MXowN3EzNCJ9.POXNeEBjRqIk39-BQI7F0A",
    }
  ).addTo(mymap);

  var markers = L.markerClusterGroup({
    chunkedLoading: true,
    iconCreateFunction: function (cluster) {
      return L.divIcon({
        html: `<div class="cluster">${cluster.getChildCount()}</div>`,
      });
    },
    polygonOptions: { color: "#8d1f7e" },
  });

  // Create a red marker

  var redIcon = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  /* const marker = L.marker(
    [
      parseFloat(adherent.personalLatitude),
      parseFloat(adherent.personalLongitude),
    ],
    { icon: redIcon }
  ).bindPopup(HTML); */

  async function placeMarkers() {
    let response = await useFetch("carte-adherents-2-s?_limit=2000");

    let archive = [];

    response.map((adherent) => {
      /* INDIVIDUELS */
      // Old
      /* if (adherent.personalZipcode && adherent.firstName && adherent.lastName) {
        personalAdherents.push(adherent);
      } */
      // New
      if (adherent.type === "individuel") {
        personalAdherents.push(adherent);
      }

      // STRUCTURES
      // Old
      /* if (
        adherent.professionalLongitude &&
        adherent.professionalLatitude &&
        adherent.structureName
      ) {
        archive.push({
          adherent: false,
          type: "structure",
          structureName: adherent.structureName,
          structureAddress: adherent.professionalFullAdress,
          structureLat: adherent.professionalLatitude,
          structureLong: adherent.professionalLongitude,
          structureWebsite: adherent.structureWebsite,
        });
        let marker = L.marker([
          parseFloat(adherent.professionalLatitude),
          parseFloat(adherent.professionalLongitude),
        ]).bindPopup(
          `${
            adherent.structureName
              ? "<span class='bold'>" + adherent.structureName + "</span><br>"
              : ""
          }
          ${
            adherent.structureWebsite
              ? "Site web : <a href='" +
                adherent.structureWebsite +
                "' target='_blank'>" +
                adherent.structureWebsite +
                "</a><br>"
              : ""
          }
           ${
             adherent.professionalPhone
               ? "Téléphone : <a href='tel:" +
                 adherent.professionalPhone +
                 "'>" +
                 adherent.professionalPhone +
                 "</a><br>"
               : ""
           }
           ${
             adherent.professionalEmail
               ? "Email : <a href='mailto:" +
                 adherent.professionalEmail +
                 "'>" +
                 adherent.professionalEmail +
                 "</a><br>"
               : ""
           }`
        );
        if (adherent.structureWebsite) {
          marker.options.icon = redIcon;
        }
        markers.addLayer(marker);
      } */
      // New
      if (adherent.type === "structure") {
        if (
          adherent.structureWebsite &&
          adherent.structureWebsite.substring(0, 4) !== "http"
        ) {
          adherent.structureWebsite = "http://" + adherent.structureWebsite;
        }

        let marker = L.marker([
          parseFloat(adherent.structureLat),
          parseFloat(adherent.structureLong),
        ]).bindPopup(
          `${
            adherent.structureName
              ? "<span class='bold'>" + adherent.structureName + "</span><br>"
              : ""
          }
          ${adherent.structureAddress ? adherent.structureAddress + "<br>" : ""}
          ${
            adherent.structureWebsite
              ? "Site web : <a href='" +
                adherent.structureWebsite +
                "' target='_blank'>" +
                adherent.structureWebsite +
                "</a><br>"
              : ""
          }`
        );
        if (adherent.adherent) {
          marker.options.icon = redIcon;
        }
        markers.addLayer(marker);
      }
    });
    // postArchive(archive);
    mymap.addLayer(markers);
  }

  placeMarkers();
  handleSearch();
}

async function handleSearch() {
  const $zip = document.getElementById("zip");
  const $zipList = document.getElementById("zipList");
  $zip.addEventListener("input", (ev) => {
    ev.preventDefault();
    $zipList.innerHTML = "";
    personalAdherents.map((adherent) => {
      if (
        adherent.zipcode.substring(0, $zip.value.length) === $zip.value &&
        $zip.value.length > 1
      ) {
        $zipList.innerHTML += `<li style="margin:0.5rem;">${capitalizeFirstName(
          adherent.firstName
        )} ${adherent.lastName.toUpperCase()} ${
          adherent.profession ? ", " + adherent.profession : ""
        } (${adherent.zipcode})</li>`;
      }
    });
  });
}

async function renderMap() {
  const $main = document.getElementById("main");
  const $banner = document.querySelector(".banner");
  $banner.style.display = "none";
  $main.classList.remove("grid");
  /* $main.innerHTML = `<h1>Carte du réseau</h1>
    <div id="mapid" class="map"></div>`; */
  let blocsHTML = await renderBlocs();

  $main.innerHTML = `<section class="map-section">
        <div class="ariane">
         <a href="/index.html">Accueil</a> / <a href="/anrat/">ANRAT</a> / Carte du réseau
        </div>
        <h1>Carte du réseau</h1>
        <!-- <p>La carte du réseau sera bientôt disponible.</p> -->
        <h3>Nos structures adhérentes <img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"> ou simples partenaires<img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"></h3>
        <p style="font-size:1rem; margin: 1rem 0 2rem 0;"><img src="https://res.cloudinary.com/anrat/image/upload/v1639045201/clusters_xps86d.png" alt="regroupement" class="cluster-legend"> Regroupements adhérents, cliquez dessus pour zoomer.</p>
        <div id="mapid" class="map"></div>
        <hr>
        <h3 style="margin-top:2.5rem;">Recherchez parmi tous nos adhérents individuels</h3>
        <p>Pour rechercher parmi nos adhérents, tapez le numéro de département ou le code postal :</p>
          <input type="text" name="departement" id="zip">
        <ul id="zipList" class="zip-list">
        </ul>
        <p style="font-size:1rem;">
          Seuls les noms de celles et ceux, adhérents pour l'année en cours et qui ont autorisé la communication de leurs coordonnées apparaissent. Pour toute réclamation ou demande, envoyez un email à <a href="mailto:contact@anrat.net">contact@anrat.net</a>
        </p>
      </section>
      <div class="blocs">
        ${blocsHTML}
      </div>`;

  setTimeout(setMap, 100);
}

export { renderMap };
