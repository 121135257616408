import { useFetch } from "./fetch";
import {
  string_to_slug,
  cloudinarySetup,
  stripHtml,
  compare,
  compareDate,
  renderTitle,
  addItalic,
} from "./utils";
import { renderBlocs } from "./blocs";

async function renderList(slug) {
  const $main = document.getElementById("main");
  $main.classList.remove("grid");
  $main.innerHTML = "";
  let ariane = [];
  let items = {
    data: {},
    html: "",
    slug: "",
  };
  let cta = {
    text: "EN SAVOIR PLUS",
    href: "",
  };

  switch (slug) {
    case "equipe":
      items.data.permanente = await useFetch(
        "anrat-equipes?category=Permanente&_sort=sortName:asc"
      );
      items.data.ca = await useFetch(
        "anrat-equipes?category=CA&_sort=sortName:asc"
      );
      items.data.formateurs = await useFetch(
        "anrat-equipes?category=Formateurs&_sort=sortName:asc"
      );
      items.slug = "equipe";
      ariane = ["ANRAT", "Qui sommes-nous ?"];
      break;
    case "partenaires":
      items.data.institutionels = await useFetch(
        "anrat-partenaires?category=Institutionnels&_sort=sortName:asc"
      );
      items.data.operationnels = await useFetch(
        "anrat-partenaires?category=Operationnels&_sort=sortName:asc"
      );
      items.data.autres = await useFetch(
        "anrat-partenaires?category=Autres&_sort=sortName:asc"
      );
      items.slug = "partenaires";
      ariane = ["ANRAT", "Nos partenaires"];
      break;
    case "liste-formations-anrat":
      items.data = await useFetch(
        "formations?category=ANRAT&_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "formations";
      ariane = ["Formations", "Les formations de l'ANRAT"];
      break;
    case "liste-formations-autres":
      items.data = await useFetch(
        "formations?category=Autres&_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "formations";
      ariane = ["Formations", "Les formations de nos partenaires"];
      break;

    case "publications":
      items.data = await useFetch(
        "ressource-publications?anrat_ne=Autre&_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "ressources/publications";
      ariane = ["Ressources", "Les publications de l'ANRAT"];
      break;
    case "outils":
      items.data = await useFetch(
        "ressource-outils?anrat_ne=Autre&_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "ressources";
      ariane = ["Ressources", "Les outils de l'ANRAT"];
      cta = {
        text: "Lire le PDF",
      };
      break;
    case "en-ligne":
      items.data = await useFetch(
        "ressource-en-lignes?_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "ressources";
      ariane = ["Ressources", "Ressources en ligne"];
      cta = {
        text: "Visiter le site web",
      };
      break;
    case "reference":
      items.data = await useFetch(
        "ressource-textes?_sort=order:ASC,creationDate:DESC"
      );
      items.slug = "ressources";
      ariane = ["Ressources", "Textes de référence"];
      cta = {
        text: "Lire le PDF",
      };
      break;
    case "actualites":
      items.data = await useFetch("actualites?_sort=creationDate:DESC");
      items.slug = "actualites";
      ariane = ["Actualités"];
      break;

    case "piece-demontees":
      // items.data = {};
      items.data = await useFetch(
        "ressources-pieces-de-montees?_sort=created_at:DESC"
      );
      items.slug = "ressources/piece-demontees";
      ariane = ["Ressources", "Pièces (dé)montées - Nouvelle formule"];
      break;

    default:
      console.error("404");
  }

  renderTitle(
    ariane[1] ? ariane[1] : ariane[0],
    ariane[1] ? ariane[1] : ariane[0]
  );

  if (
    slug !== "equipe" &&
    slug !== "partenaires" &&
    slug !== "actualites" &&
    items.slug !== "formations"
  ) {
    items.data.map((item, id) => {
      if (slug === "outils" || slug === "reference") {
        cta.href = item.pdf?.url || item.pdfUrl;
      } else if (slug === "en-ligne") {
        cta.href = item.website;
      }

      let categoryHTML = ``;
      if (
        slug === "publications" ||
        slug === "outils" ||
        slug === "en-ligne" ||
        slug === "reference"
      ) {
        if (item.category) {
          categoryHTML = `<p><strong>${item.category}</strong></p>`;
        }
      }

      items.html += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            ${categoryHTML}
            <p>${
              slug === "outils" || slug === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
        slug !== "outils" &&
        slug !== "en-ligne" &&
        stripHtml(item.content).length > 280
          ? "..."
          : ""
      }</p>
            <a href="${
              cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : cta.href
            }" class="out-btn-black" ${
        cta.href == "" ? "" : 'target="_blank"'
      }>${cta.text}</a>
          </div>
        </div>
        ${id != items.data.length - 1 ? "<hr>" : ""}`;
    });
  } else if (slug === "equipe") {
    $main.classList.add("equipe");

    /* items.data.permanente.sort(compare).reverse(); */
    items.html += "<h2>Équipe permanente</h2>";
    items.data.permanente.map((member, id) => {
      items.html += `<div class="list__item">
          <div class="text">
            <h3>${member.name}</h3>
            <p>${member.function}</p>
            <p><a href="mailto:${member.email}">${member.email}</a> / ${
        member.phone
      }</p>
            ${items.data.permanente.length - 1 != id ? "<hr>" : ""}
          </div>
        </div>`;
    });

    // items.data.ca.sort(compare).reverse();
    items.html += "<h2>Conseil d'Administration</h2>";
    items.data.ca.map((member, id) => {
      items.html += `<div class="list__item">
          <div class="text">
            <h3>${member.name}</h3>
            <p>${member.function}</p>
            ${items.data.ca.length - 1 != id ? "<hr>" : ""}
          </div>
        </div>`;
    });

    // items.data.formateurs.sort(compare).reverse();
    items.html += "<h2>Formateurs</h2>";
    items.data.formateurs.map((member, id) => {
      items.html += `<div class="list__item">
          <div class="text">
            <h3>${member.name}</h3>
            <p>${member.function}</p>
            ${items.data.formateurs.length - 1 != id ? "<hr>" : ""}
          </div>
        </div>`;
    });
  } else if (slug === "partenaires") {
    $main.classList.add("partenaires");

    // items.data.institutionels.sort(compare).reverse();
    items.html += "<h2>Nos partenaires Institutionnels et financiers</h2>";
    items.html += '<div class="cards">';
    items.data.institutionels.map((partner) => {
      items.html += `
      <a href="${partner.website}" class="card" target="_blank">
          <img src="${partner.image?.url || partner.cloudinaryUrl}" alt="${
        partner.name
      }">
          <hr>
          <p>${partner.name}</p>
        </a>
        `;
    });
    items.html += "</div>";

    // items.data.operationnels.sort(compare).reverse();
    items.html += "<h2>Nos partenaires opérationnels</h2>";
    items.html += '<div class="cards">';
    items.data.operationnels.map((partner) => {
      items.html += `
      <a href="${partner.website}" class="card" target="_blank">
          <img src="${partner.image?.url || partner.cloudinaryUrl}" alt="${
        partner.name
      }">
          <hr>
          <p>${partner.name}</p>
        </a>
        `;
    });
    items.html += "</div>";

    // items.data.autres.sort(compare).reverse();
    items.html += "<h2>Nos autres partenaires</h2>";
    items.html += '<div class="cards">';
    items.data.autres.map((partner) => {
      items.html += `
      <a href="${partner.website}" class="card" target="_blank">
          <img src="${partner.image?.url || partner.cloudinaryUrl}" alt="${
        partner.name
      }">
          <hr>
          <p>${partner.name}</p>
        </a>
        `;
    });
    items.html += "</div>";
  } else if (slug === "actualites") {
    let archives = "";
    let nonArchives = "";
    items.data.map((item, id) => {
      if (item.archive) {
        if (id < 30) {
          archives += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            (item.image?.url && cloudinarySetup(item.image?.url)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            <p class="bold">${addItalic(item.subtitle)}</p>
            <p>${
              slug === "outils" || slug === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
            slug !== "outils" &&
            slug !== "en-ligne" &&
            stripHtml(item.content).length > 280
              ? "..."
              : ""
          }</p>
            <a href="${
              cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : cta.href
            }" class="out-btn-black" ${
            cta.href == "" ? "" : 'target="_blank"'
          }>${cta.text}</a>
          </div>
        </div>
        ${id != items.data.length - 1 ? "<hr>" : ""}`;
        }
      } else {
        nonArchives += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            (item.image?.url && cloudinarySetup(item.image?.url)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            <p class="bold">${addItalic(item.subtitle)}</p>
            <p>${
              slug === "outils" || slug === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
          slug !== "outils" &&
          slug !== "en-ligne" &&
          stripHtml(item.content).length > 280
            ? "..."
            : ""
        }</p>
            <a href="${
              cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : cta.href
            }" class="out-btn-black" ${
          cta.href == "" ? "" : 'target="_blank"'
        }>${cta.text}</a>
          </div>
        </div>
        ${id != items.data.length - 1 ? "<hr>" : ""}`;
      }
    });
    items.html =
      nonArchives +
      `<div class="title archives"><h1>Ça a eu lieu</h1></div>` +
      archives;
  } else if (items.slug === "formations") {
    let archives = "";
    let nonArchives = "";
    items.data.map((item, id) => {
      if (!item.open) {
        if (id < 30) {
          archives += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            <p>${
              slug === "outils" || slug === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
            slug !== "outils" &&
            slug !== "en-ligne" &&
            stripHtml(item.content).length > 280
              ? "..."
              : ""
          }</p>
            <a href="${
              cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : cta.href
            }" class="out-btn-black" ${
            cta.href == "" ? "" : 'target="_blank"'
          }>${cta.text}</a>
          </div>
        </div>
        ${id != items.data.length - 1 ? "<hr>" : ""}`;
        }
      } else {
        nonArchives += `<div class="list__item">
          <img src="${
            (item.image?.formats?.thumbnail?.url &&
              cloudinarySetup(item.image?.formats?.thumbnail?.url)) ||
            (item.imageUrl && cloudinarySetup(item.imageUrl)) ||
            "https://res.cloudinary.com/anrat/image/upload/f_auto/thumbnail_Sans_titre_537095d2d7.png"
          }" alt="ANRAT">
          <div class="text">
            <h2>${addItalic(item.title)}</h2>
            <p>${
              slug === "outils" || slug === "en-ligne"
                ? stripHtml(item.content)
                : stripHtml(item.content).substring(0, 280)
            } ${
          slug !== "outils" &&
          slug !== "en-ligne" &&
          stripHtml(item.content).length > 280
            ? "..."
            : ""
        }</p>
            <a href="${
              cta.href == ""
                ? "/" + items.slug + "/" + string_to_slug(item.title)
                : cta.href
            }" class="out-btn-black" ${
          cta.href == "" ? "" : 'target="_blank"'
        }>${cta.text}</a>
          </div>
        </div>
        ${id != items.data.length - 1 ? "<hr>" : ""}`;
      }
    });
    items.html =
      nonArchives +
      `<div class="title archives"><h1>Ça a eu lieu</h1></div>` +
      archives;
  } else {
    console.error("404");
  }

  let introText = "";
  console.log(slug);
  if (slug === "piece-demontees") {
    let introTextData = await useFetch("ressource-pages?_id=13");
    console.log(introTextData);
    introText = introTextData[0].content;
  }

  let blocsHTML = await renderBlocs();

  $main.innerHTML = `<div class="list">
        <div class="ariane">
         <a href="/index.html">Accueil</a> / <a href="/${
           window.location.href.split("/")[3]
         }">${ariane[0]}</a> / ${ariane[1] ? ariane[1] : ""}
        </div>
        <div class="title">
          <img src="https://res.cloudinary.com/anrat/image/upload/f_auto/formations_846a20910d.png" alt="Formations">
          <h1>${ariane[1] || ariane[0]}</h1>
        </div>
        ${introText}
        ${items.html}
      </div>
  <div class="blocs">
        ${blocsHTML}
      </div>`;
}

export { renderList };
